import React from 'react';
import { Button,Dimensions, Text, View } from 'react-native';
import { getLanguage } from '../components/languages';
import { styles } from '../components/styles';

export default function SettingsScreen({ navigation }){


    
    return (<View>

        <Button title="Get language" onPress={() => getLanguage()}></Button>
        <Text style={styles.textCenter}>{Dimensions.get('window').width.toFixed()} x {Dimensions.get('window').height.toFixed()}</Text>        
    </View>);
    
}