import React from 'react';
import { ActivityIndicator, Dimensions, Image, Modal,Pressable, Text, TextInput, View, SafeAreaView, FlatList, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Constants from 'expo-constants';
import { createMultiStyleIconSet, FontAwesome } from '@expo/vector-icons';
import { RadioButton } from 'react-native-paper';

import { AuthContext} from '../components/authcontext';
import { OrderContext } from '../components/ordercontext';
import { GetRequest, PostRequest } from '../components/dataservice';
import { i18n } from '../components/languages';
import { styles, theme } from '../components/styles';
import Logger from '../components/logger';



function MenuDetails({navigation, route }){
    const [state, setState] = React.useState({data: null, selectedId: null, selectedItem: null, 
        modalVisible: false, quantity: 1, selectItem: null, message: null, modifierDetails: null, 
        options: [], modifierDetailsSelected: [], color: theme.colors.black, shotcutOpenModifier: true,
        orders: 0, openModifierValue: null, isFetching: true});

    const { signOut } = React.useContext(AuthContext);
    const { checkTable , addOrderItem } = React.useContext(OrderContext);
    const openModifierId = "00016";
    Logger.set();


    /// https://reactnavigation.org/docs/params
    const { id, title, table } = route.params;
  

    // https://reactnavigation.org/docs/tab-based-navigation/#add-badges-to-icons
    // https://reactnavigation.org/docs/header-buttons/
    React.useEffect(() =>{
        navigation.setOptions({title: title,
            headerRight: () => (<View style={[styles.row,styles.headerIcon, {color:state.color}]} >
                <FontAwesome name="shopping-basket" style={[{color:state.color}]} size={24} color="black"
                    onPress={() => { navigation.navigate('Orders', { screen: 'TableDetailScreen' });}} />
                {state.orders > 0 && <Text style={[{color:state.color}]}>{state.orders}</Text>}
            </View>)
        });

        if(state.data === null){
            bootstrapAsync();
        }
    },[state]);



    const bootstrapAsync = async() => {
        Logger.log("menuDetails bootstrapAsync");

        var response = await GetRequest(Constants.manifest.extra.getMenuDetails + id);
        // Logger.log(response);
        if(response.status && response.status > 400){
            signOut();
        }else{
            setState({...state, data:response.data, isFetching: false});
        }
        
    }


    const onRefresh = async () => {
        setState({...state, isFetching: true});
        bootstrapAsync();
    };

    
    const renderItem = ({ item }) => {
        //Logger.log(item.menuCode === state.selectedId);
        let backgroundColor = item.menuCode === state.selectedId ? theme.colors.gray : theme.colors.surface;
        let color = item.menuCode === state.selectedId ? theme.colors.white : theme.colors.black;
        
        return (
            <TouchableOpacity onPress={() => clickMenu(item)} style={[styles.item, styles.row, {backgroundColor:backgroundColor}]}>
                <View style={[styles.itemFront]}><Image style={styles.image} source={{uri: item.photo}} /></View>
                <Text style={[styles.itemCenter, styles.title, {color:color}]}>{item.desc} </Text>
                <Text style={[styles.itemBack, styles.title, {color:color}]}> {item.price.toLocaleString()} </Text>
            </TouchableOpacity>
        );
    };



    const renderItemModifier = ({ item }) => {
        //Logger.log("renderItemModifier state.modifierDetailsSelected", state.modifierDetailsSelected);
        //Logger.log("renderItemModifier state.selectedItem.modifiers", state.selectedItem.modifiers);
        //let options = state.options; 
        let modifierDetailsSelected = state.modifierDetailsSelected.length > 0 ? state.modifierDetailsSelected:
                                    state.selectedItem.modifiers === undefined ? []: state.selectedItem.modifiers;
                                        
        let options = modifierDetailsSelected.map(({modId}) => modId);

        let selected = options && options.indexOf(item.moId) >= 0;
        // Logger.log("seleted ", selected);
        let backgroundColor = selected ? theme.colors.gray : theme.colors.surface;
        let color = selected ? theme.colors.white : theme.colors.black;

        let modifierDetailSelected = modifierDetailsSelected.find(({modId}) => modId === item.moId);

        return (<Pressable onPress={() => {setOptionModifierDetails(item.moId)}} key={item.id+item.moId} 
            style={[styles.item, styles.buttonOption, { backgroundColor:backgroundColor, color:color} ]}>  
            <Text style={styles.textCenter}>{selected ? modifierDetailSelected.desc: item.desc}</Text>
            {item.price > 0 && <Text>{item.price}</Text>}
        </Pressable>);
    };



    const OrderView = ({getOptionModifierDetails, addOrder, close}) => 
    (<View style={[styles.container, {justifyContent: 'flex-end'}]}> 
        {state.selectedItem.isSet && <View style={styles.rowFull}>
            <RadioButton.Group value={state.selectItem} onValueChange={value => setState({...state, selectItem: value})}>
            {state.selectedItem.selectItems.map((selectItem, index) => 
                <RadioButton.Item styles={{margin: 0, padding: 0}} label={selectItem.desc} value={selectItem.menuCode} key={index +""+ selectItem.menuCode} />
            )}
            </RadioButton.Group>
        </View>}

        {Logger.log("OrderView state.selectedItem.modifiers", state.selectedItem.modifiers)}
        {state.selectedItem.modifiers && state.selectedItem.modifiers.length > 0 && <View style={styles.rowFull}>
            {state.selectedItem.modifiers.map((modifierDetail, index) => 
                <Text style={styles.text} key={index +""+ modifierDetail.modId}>{modifierDetail.desc}
                {state.selectedItem.modifiers.length-1 === index ? "": ", "}</Text>)}
        </View>}
              
        <View style={[styles.rowFull]}>
        <Pressable style={[styles.button, {backgroundColor: theme.colors.brightcyan}]} 
                onPress={() => getOptionModifierDetails(null)} >
            <Text style={[styles.textButton]}>{i18n.t('OPTIONS')}</Text>
        </Pressable>
                    
        <Pressable style={[styles.button, {backgroundColor: theme.colors.yellow}]} 
                onPress={() => getOptionModifierDetails("")} >
            <Text style={[styles.textButton]}>{i18n.t('ADDITION')}</Text>
        </Pressable>
        </View>
                
        <View style={[styles.rowFull]}>
            <Pressable style={styles.buttonSquare} 
                        onPress={() => {let qty = state.quantity-1; if(qty > 0) setState({...state, quantity: qty})}}>
                <FontAwesome name="minus" size={24} color={theme.colors.black} />
            </Pressable>
            <TextInput style={styles.inputNumberCenter} keyboardType="numeric" selectTextOnFocus
                        onChangeText={(value) => setState({...state, quantity: isNaN(parseInt(value)) ? 0: parseInt(value)})} 
                        value={state.quantity.toString()} />
            <Pressable style={styles.buttonSquare} 
                        onPress={() => setState({...state, quantity: state.quantity+1})}>
                <FontAwesome name="plus" size={24} color={theme.colors.black} />
            </Pressable>
        </View>

        {state.message !== null && <View><Text style={styles.textWarning}>{state.message}</Text></View>}
       
        <View style={[Dimensions.get('window').height > 640 ? styles.column: styles.rowFull]}>
        <Pressable style={[styles.button, styles.buttonPrimary]} 
                onPress={() => addOrder()} >
            <Text style={styles.textButton}>{i18n.t('ORDERS')}</Text>
        </Pressable>

        <Pressable style={[styles.button, styles.buttonSecondary]} 
                onPress={() => close()}>
            <Text style={styles.textButton} >{i18n.t('close')}</Text>
        </Pressable>
        </View>
    </View>);


    const OptionsView = ({ item, addModifierDetailsSelected }) => 
    (<View style={[styles.container,  {justifyContent: 'flex-end'}]}>
        <FlatList data={state.modifierDetails} numColumns={3} 
                renderItem={renderItemModifier} 
                keyExtractor={item => item.moId} />

        <View style={[styles.rowFull, Dimensions.get('window').height > 640 ? styles.column: styles.rowFull]}>
            <Pressable style={[styles.button, styles.buttonPrimary]} 
                    onPress={addModifierDetailsSelected} >
                <Text style={[styles.textButton]}>{i18n.t('ADD')}</Text>
            </Pressable>
            <Pressable style={[styles.button, styles.buttonClose]} 
                    onPress={() => {setState({...state, modifierDetailsSelected: null, modifierDetails: null})}}>
                <Text style={styles.textButton}>{i18n.t('close')}</Text>
            </Pressable>
        </View>
    </View>);

 
    const OptionOpenModifierView = ({saveOpenModifier, resetOpenModifier}) => 
    (<View style={[styles.container, {justifyContent: 'flex-end'}]}>
        <Text style={[styles.text]}>{i18n.t('OPEN_MODIFIER')}</Text>
        {Platform.OS === 'web' ?
        <TextInput style={[styles.input, styles.text]}  defaultValue={state.openModifierValue} selectTextOnFocus
            onBlur={(e) => {setState({...state, openModifierValue: e.nativeEvent.text}); }}
            returnKeyType='default' />:
        <TextInput style={[styles.input, styles.text]}  defaultValue={state.openModifierValue} selectTextOnFocus
            onSubmitEditing={(e) => {console.log("onSubmitEditing", e.nativeEvent.text); setState({...state, openModifierValue: e.nativeEvent.text});}}
            keyboardType='default' returnKeyType='default' placeholder="press Enter before save."
        />}

        <View style={[Dimensions.get('window').height > 640 ? styles.column: styles.rowFull]}>
        <Pressable style={[styles.button, styles.buttonOpen]} 
                onPress={() => { saveOpenModifier(state.openModifierValue);}} >
            <Text style={styles.textButton}>{i18n.t('save')}</Text>
        </Pressable>
        <Pressable style={[styles.button, styles.buttonClose]} 
                onPress={resetOpenModifier} >
            <Text style={styles.textButton}>{i18n.t('close')}</Text>
        </Pressable>
        </View>
    </View>);


    const resetOpenModifier = () => {
        let modifierDetails = state.shotcutOpenModifier ? null: state.modifierDetails;
        let modifierDetailsSelected = state.modifierDetailsSelected;
        let modifierDetailSelected = modifierDetailsSelected.find(({modId}) => modId === openModifierId);

        let index = modifierDetailsSelected.indexOf(modifierDetailSelected);
        modifierDetailsSelected.splice(index, 1);
        setState({...state, modifierDetailsSelected: modifierDetailsSelected, modifierDetails: modifierDetails, openModifierValue: null});
    }


    const getOptionModifierDetails = async(openModifier) => {

        Logger.log(Constants.manifest.extra.getModifierDetails);
        let response = await GetRequest(Constants.manifest.extra.getModifierDetails);
        Logger.log("getOptionModifierDetails", response);

        if(typeof response === 'string'){
            setState({...state, messages: response});
        }else{
            // get modifierDetails for server
            let modifierDetails = response.data;
            // get modifierDetailsSelected is openModifierId
            let modifierDetailsSelected = state.modifierDetailsSelected !== null ? state.modifierDetailsSelected:
                                (state.selectedItem.modifiers === undefined ? []: JSON.parse(JSON.stringify(state.selectedItem.modifiers)));
            let shotcutOpenModifier = false;
           
            
            Logger.debug("openModifier", openModifier);
            if(openModifier === null){

            }else{
                let modifierDetailSelected = modifierDetailsSelected.find(({modId}) => modId === openModifierId);
                shotcutOpenModifier = true;
                if(modifierDetailSelected === undefined){
                    modifierDetailsSelected.push({modId: openModifierId, desc: openModifier});
                }else{
                    modifierDetailSelected.desc = openModifier;
                }
            }


            setState({...state, messages: null, modifierDetailsSelected: modifierDetailsSelected, 
                modifierDetails: modifierDetails, openModifier: openModifier, shotcutOpenModifier: shotcutOpenModifier });
        }
    }

    // select OptionModifier
    const setOptionModifierDetails = (moId) => {
        Logger.log("setOptionModifierDetails state.modifierDetailsSelected", state.modifierDetailsSelected);
        Logger.log("setOptionModifierDetails state.selectedItem.modifiers ", state.selectedItem.modifiers );
        let modifierDetailsSelected = state.modifierDetailsSelected !== null ? state.modifierDetailsSelected:
                                (state.selectedItem.modifiers === undefined ? []: JSON.parse(JSON.stringify(state.selectedItem.modifiers)));
        Logger.log("modifierDetailsSelected", modifierDetailsSelected)

        let options = modifierDetailsSelected.map(({modId}) => modId);
        let index = options.indexOf(moId);

        if(index < 0){
            let modifierDetails = state.modifierDetails;
            //options.push(moId);
            let modifierDetail = modifierDetails.find((mo) => mo.moId === moId);
            //Logger.log('modifierDetail', modifierDetail)
            modifierDetailsSelected.push({modId: modifierDetail.moId, desc: moId === openModifierId ? "" : modifierDetail.desc})
        }else{
            modifierDetailsSelected.splice(index, 1);
        }

        Logger.log("setOptionModifierDetails", modifierDetailsSelected);
        Logger.log("setOptionModifierDetails state.selectedItem.modifiers ", state.selectedItem.modifiers );
        setState({...state, modifierDetailsSelected: modifierDetailsSelected});
    }


    const saveOpenModifier = (value) => {
        let modifierDetailsSelected = state.modifierDetailsSelected;
        let modifierDetailSelected = modifierDetailsSelected.find(({modId}) => modId === openModifierId);
        modifierDetailSelected.desc = value === undefined ? state.openModifierValue: value;

        let index = modifierDetailsSelected.indexOf(modifierDetailSelected);
        modifierDetailsSelected[index] = modifierDetailSelected;

        console.log("modifierDetailsSelected", modifierDetailsSelected);

        setState({...state, modifierDetailsSelected: modifierDetailsSelected, openModifierValue: null});
    }



    // add selected id in options array to modifierDetailsSelected.
    const addModifierDetailsSelected = () => {
        // DOTO
        //let modifierDetailsSelected = state.modifierDetails.filter(({moId}) => state.options.indexOf(moId) >= 0);
        let selectedItem = state.selectedItem;
        selectedItem.modifiers = state.modifierDetailsSelected;
        
        setState({...state, selectedItem: selectedItem, modifierDetailsSelected: null, modifierDetails: null});
    }


    const ModalView = ({modifierDetails, showOpenModifier, item, saveOpenModifier, 
        resetOpenModifier,getOptionModifierDetails, addOrder, close }) => (<View style={styles.centeredView}>
        <View style={styles.modalView}>
            <Image style={[Dimensions.get('window').height > 640 ? styles.imageFull: styles.image]} source={{uri: state.selectedItem.photo}} />
            <View style={styles.row}>
                <Text style={[styles.h5, styles.textCenter]}>{state.selectedItem.desc} </Text>
                {/*<Text style={styles.itemBack}>{state.selectedItem.price.toLocaleString()}</Text>*/}
            </View>
       
            {modifierDetails === null ? <OrderView getOptionModifierDetails={getOptionModifierDetails}
                addOrder={addOrder} close={close} />:
                showOpenModifier ? <OptionOpenModifierView  saveOpenModifier={saveOpenModifier}  resetOpenModifier={resetOpenModifier} />:
                    <OptionsView item={item} addModifierDetailsSelected={addModifierDetailsSelected} />
            }
        </View>
    </View>);


    function close(orders){
        //await checkTable(table.tableId, table);

        orders = orders === undefined ? 0: orders;
        let color = orders > 0 ? theme.colors.red: theme.colors.black;
        setState({...state, selectedId: null, selectedItem: null, modalVisible: false, orders: orders, 
            color: color,  quantity: 1, selectItem: null, options: [], modifierDetailsSelected: []});
    }


    /// https://stackoverflow.com/questions/43869197/check-if-an-array-is-empty-in-react-native
    return (state.data === null ? 
        <View style={styles.containerCenter}>
            <View style={styles.column}>
                <ActivityIndicator size="large" color={theme.colors.secondary} />
                <Text style={styles.textCenter}>Loading</Text>
            </View>
        </View> :  
        <SafeAreaView style={styles.container}>
            <FlatList data={state.data} renderItem={renderItem} keyExtractor={item => item.indexNo +""+ item.menuCode} 
                    onRefresh={onRefresh} refreshing={state.isFetching}/>

            {state.selectedItem !== null &&
            <Modal style={[styles.height90pc, styles.width90pc]} animationType="slide" transparent={true} visible={state.modalVisible}>
                <ModalView item={state.selectedItem} 
                    showOpenModifier={state.modifierDetailsSelected !== null && state.modifierDetailsSelected.find(({modId, desc}) => modId === openModifierId && desc === "") !== undefined} 
                    modifierDetails={state.modifierDetails} 
                    saveOpenModifier={saveOpenModifier}
                    resetOpenModifier={resetOpenModifier}
                    getOptionModifierDetails={getOptionModifierDetails}
                    addOrder={addOrder} close={close} />
            </Modal>}
        </SafeAreaView>
    );


    // click a menu to order
    function clickMenu(item){
        // set selected menu to selectedItem
        setState({...state, selectedId: item.id, selectedItem: JSON.parse(JSON.stringify(item)), modalVisible: !state.modalVisible});       
    }


    async function addOrder(){
        //Logger.log("addOrder", state.selectedItem.menuCode +" ("+ state.selectedItem.desc + ") x " + state.quantity);
        let item = state.selectedItem;
        item.qty = state.quantity;
        //Logger.log("selectedItem", item);

        Logger.log("addOrder", item);
        if(item.isSet)
        {
            if(state.selectItem === null)
            {
                setState({...state, message: "please select a option!"});
            }else{
                addOrderItem(item);

                let _items = item.selectItems.filter((selectItem) => selectItem.menuCode === state.selectItem);
                if(_items.length > 0){
                    let _item = _items[0];
                    _item.qty = 1;
                    Logger.debug(_item);
                    addOrderItem(_item);
                }
      
                close(state.orders+1);
            }
        }else{
            addOrderItem(item);
            close(state.orders+1);
        }

        
    }

}


export default MenuDetails;