import React from 'react';
import {View, Text, TouchableOpacity, Image } from 'react-native';
import { i18n } from '../components/languages';
import { styles, theme } from '../components/styles';
import { Ionicons } from '@expo/vector-icons'; 


function TableViewBasic({item, onPress, backgroundColor, textColor }){
    
    let oTime = "";

    if (item.openTime != null
      && item.openTime != undefined
      && item.openTime != "") {
        let openDate = new Date(item.openTime);
        let hh = openDate.getHours();
        let mm = openDate.getMinutes();

        if (hh < 10) {
            hh = "0" + hh;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }

        oTime = hh + ":" + mm;
    } else {
        oTime = "";
    }
    
    //console.log(item);

    return (
        item.status === "blank"
        ? <TouchableOpacity onPress={onPress} style={[styles.item, textColor, backgroundColor, {flex: 1} ]} >         
            <View style={[styles.tableView]}>
                <View style={styles.row}><Text> </Text></View>
                <View style={[styles.rowCenter, styles.flex]}>
                    <Image style={[styles.row, styles.tableBlank]} source={require('../assets/tableBlank.png')} />
                    <Text style={[styles.h5, styles.textCenterImage]}>{item.tableNo}.</Text>
                </View>
            </View>
          </TouchableOpacity>
        : <TouchableOpacity onPress={onPress} style={[styles.item, textColor, backgroundColor, {flex: 1} ]} >            
            <View style={[styles.tableView]}>
                <View style={styles.row}>
                    <View style={[styles.row, styles.innerTopLeft]}>
                        <Ionicons name="person" size={theme.fontSize} color="black" style={{marginVertical: 2}} />
                        <Text style={styles.text}> {item.customers}</Text>
                    </View>
                    <View style={styles.innerTopRight}><Text style={styles.text}>{item.billNo}</Text></View>
                </View>
                
                <View style={[styles.rowCenter, styles.flex]}>
                {item.status === "billing" ?
                    <Image style={styles.tableBilling} source={require('../assets/tableBilling.png')} />:
                    <Image style={styles.tableBilling} source={require('../assets/blank.png')} />}
                    <Text style={[styles.h5, styles.textCenterImage]}>{item.tableNo}.</Text>
                </View>

                <View style={styles.row}>
                    <View style={[styles.innerBottomLeft]}>
                        <Text>{'\u0E3F'} {item.billTotal}</Text>
                        { oTime !== "" && <View style={styles.row}>
                            <Text >{oTime} </Text>
                            <Text style={{color:"#555555"}}>({item.sitedHours})</Text>
                        </View>}
                    </View>

                    <View style={styles.innerBottomRight} >
                        {item.lockedEmpId !== null && item.lockedEmpId !== "" &&
                        [<Image style={styles.tableLocked} source={require('../assets/tableLocked.png')} />,
                        <Text>{item.lockedEmpId}</Text>]}
                    </View>
                </View>
            </View>
            {/* <Text>No.{item.tableNo} ({item.tableId})</Text>
            <Text>flowId {item.flowId}</Text>
            <Text>OutletID {item.outletId}</Text>
            <Text>Seat {item.seatNo} {item.sitedHours} {item.openTime}</Text>
            {item.customers > 0 && <Text>customers {item.customers}</Text>}
            {item.billNo && <Text>Bill No. {item.billNo}</Text>} 
            <Text>status {item.status} {item.billTotal > 0 && item.billTotal} </Text>
            {item.busDate && <Text>busDate {item.busDate}</Text>} */}
          </TouchableOpacity>
    );

}


export { TableViewBasic };