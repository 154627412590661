import React, { useImperativeHandle, forwardRef } from "react";
import { View, Modal, ActivityIndicator, Text } from 'react-native';
import { styles } from '../components/styles';

const ModelWait = (props, ref) => {

    useImperativeHandle(ref, () => ({
        // methods connected to 'ref'
        show: () => { showWait() },
        done: () => { hideWait() }
    })); 

    const showWait = () => {
        dispatch({ type: 'WAIT_A_MINUTE' });
    };

    const hideWait = () => {
        dispatch({ type: 'DONE' });
    }

    const[state, dispatch] = React.useReducer(
        (prevState, action) => {
            switch(action.type) {
                case 'WAIT_A_MINUTE':
                    return {
                        ...prevState,
                        wait: true
                    };
                case 'DONE':
                    return {
                        ...prevState,
                        wait: false
                    };
                default:
                    return prevState;
            }
        }, 
        { 
            wait: false
        });

    React.useEffect(() => { }, [state]);

    return(
        <Modal
            animationType="slide"
            transparent={false}
            visible={state.wait}
        >
            <View style={styles.centeredView}>
            <View style={styles.modalView}>
                <ActivityIndicator size="large" />
                <View style={styles.rowCenter}><Text style={styles.text}>Loading</Text></View>
            </View>
            </View>
        </Modal>
    );
}

export default forwardRef(ModelWait);