import React from 'react';
import { Text, View, SafeAreaView, FlatList } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { i18n } from '../components/languages';
import { styles, dpi } from '../components/styles';



function DataFiles(){
    const [selectedId, setSelectedId] = React.useState(null);
    const DATA = [ {
        id: 'F301005',
        icon: '',
        image: '',
        title: i18n.t('F301005'),
      },
      {
        id: 'F301006',
        icon: '',
        image: '',
        title: i18n.t('F301006'),
      },];


    const Item = ({ title, onPress, backgroundColor, textColor }) => (
        <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
        <Text style={[styles.title, textColor]}>{title}</Text>
        </TouchableOpacity>
    );



    const renderItem = ({ item }) => {
        const backgroundColor = item.id === selectedId ? "#999999" : "#EEEEEE";
        const color = item.id === selectedId ? 'white' : 'black';
        
        return (<Item title={item.title} onPress={() => clickMenu(item.id)} 
                      backgroundColor={{ backgroundColor }} textColor={{ color }}/>); 
    };


    function clickMenu(id){
        setSelectedId(id);
        
    }
    


    return (<View>
        <SafeAreaView style={styles.container}>
            <FlatList data={DATA} renderItem={renderItem} keyExtractor={item => item.id} />
        </SafeAreaView>
    </View>);
}


export default DataFiles;