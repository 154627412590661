import React from 'react';
import { Button, Text, View, TouchableOpacity } from 'react-native';
import { i18n, } from '../components/languages';
import { styles, dpi } from '../components/styles';



function FastFood(){

  

    return ( <View style={styles.container}>
        <Text style={[styles.textCenter]}>{i18n.t('F301014')}</Text>    
        <TouchableOpacity><Text style={[styles.textHeader, styles.textCenter]}>Take Order</Text></TouchableOpacity>
    </View>);
}


export default FastFood;