import React from 'react';
import { Text, View } from 'react-native';



function ListMenus(){



    return ( <View>
        <Text>ListMenus</Text>    
    </View>);
}


export default ListMenus;