import React from 'react';
import { FlatList, Text, SafeAreaView, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { i18n } from '../components/languages';
import { styles, dpi } from '../components/styles';

import { MaterialCommunityIcons } from '@expo/vector-icons'; 



function DemoScreen({ navigation }){
  const [selectedId, setSelectedId] = React.useState(null);

  const DATA = [
    {
          id: 'tables',
          icon: '',
          title: i18n.t('TABLES'),
        },
        {
          id: 'menus',
          icon: '',
          title: i18n.t('MENUS'),
        },
        {
          id: 'orders',
          icon: '',
          title: i18n.t('ORDERS'),
        },
        {
          id: 'tabs-bottom',
          icon: '',
          title: 'Tabs bottom',
        },
        {
          id: 'material-bottom-tabs',
          icon: '',
          title: 'MD Bottom Tabs',
        },
        {
          id: 'material-top-tabs',
          icon: '',
          title: 'Meterial Top Tabs',
        },
        {
          id: 'demo-screen',
          icon: '',
          title: "Demo Screen " +dpi+ " dpi!"
        },
        {
          id: 'F101012',
          icon: <MaterialCommunityIcons name="bread-slice-outline" size={24} color="black" />,
          title: i18n.t('F101012'),
        },
      
      ];


      function clickHomeMenu(id){
        setSelectedId(id);
        switch(id){
        //case "home": navigation.navigate("HomeScreen"); break;
        case "tables": navigation.navigate("TablesScreen"); break;
        case "menus": navigation.navigate("MenusScreen"); break;
        case "orders": navigation.navigate("OrdersScreen"); break;
        case "tabs-bottom": navigation.navigate("TabsBottomScreen"); break;
        case 'material-bottom-tabs': navigation.navigate("MeterialBottomTabs"); break;
        case 'material-top-tabs': navigation.navigate("MeterialTopTabs"); break;
        case 'demo-screen': navigation.navigate("DemoScreen"); break;
        
        case 'F101012': navigation.navigate("MenuGroupings"); break;
    
        default: alert("click id " + id); break;
        }
    };


    const Item = ({ icon, title, onPress, backgroundColor, textColor }) => (
        <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
        <Text style={[styles.title, textColor]}>{typeof(icon) === "object" ? icon :''}{title}</Text>
        </TouchableOpacity>
    );



    const renderItem = ({ item }) => {
        const backgroundColor = item.id === selectedId ? "#999999" : "#EEEEEE";
        const color = item.id === selectedId ? 'white' : 'black';
        
        return (<Item title={item.title} icon={item.icon} onPress={() => clickHomeMenu(item.id)} 
                      backgroundColor={{ backgroundColor }} textColor={{ color }}/>); 
    };



    


    return (
        <SafeAreaView style={styles.container}>
            <FlatList data={DATA} renderItem={renderItem} keyExtractor={item => item.id} />
        </SafeAreaView>
    );

    return (<View>
        <Text>DemoScreen</Text>    
    </View>);
}


export default DemoScreen;