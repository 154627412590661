import { registerRootComponent } from 'expo';
import 'expo-asset';
import { AppRegistry } from 'react-native';

import Config from "react-native-config";
import App from './App';
//import StorybookUI from './storybook'



console.log("Config", Config);
console.log("Config.LOAD_STORYBOOK", Config.LOAD_STORYBOOK === 'true');


if(Config.LOAD_STORYBOOK === 'true'){
    console.log("storybook");
    //AppRegistry.registerComponent('AppName', () => StorybookUI);
}else{
    console.log("app");
    AppRegistry.registerComponent('AppName', () => App);
    // registerRootComponent calls AppRegistry.registerComponent('main', () => App);
    // It also ensures that whether you load the app in Expo Go or in a native build,
    // the environment is set up appropriately
    registerRootComponent(App);
};