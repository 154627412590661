import React from 'react';
import { Button, Text, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialCommunityIcons  } from '@expo/vector-icons';
/// icon => https://icons.expo.fyi/
import { styles, dpi } from '../components/styles';

import HomeMenu from './homemenu';
import MenusScreen from './menusscreen';
import OrdersScreen from './ordersscreen';
import TablesScreen from './tablesscreen';


const Tab = createBottomTabNavigator();

/// https://reactnavigation.org/docs/bottom-tab-navigator/
function TabsBottomScreen({ navigation }){
    return (
      <Tab.Navigator initialRouteName="TablesScreen" activeColor="#f0edf6" inactiveColor="#3e2465">
        <Tab.Screen name="TablesScreen" component={TablesScreen} options={{
          headerShown: false, tabBarLabel:'Tables',
          tabBarIcon: ({ color }) => (<MaterialCommunityIcons name="table-furniture" size={24} color={color} />)}}/>
        <Tab.Screen name="MenusScreen" component={MenusScreen} options={{ 
          headerShown:false, tabBarLabel: 'Menus',
          tabBarIcon: ({ color }) => (<MaterialCommunityIcons name="book-open" size={24} color={color} />)}}/>
        <Tab.Screen name="OrdersScreen" component={OrdersScreen} options={{ 
          headerShown:false, tabBarLabel: 'Orders',
          tabBarIcon: ({ color }) => (<MaterialCommunityIcons name="order-bool-ascending" size={24} color={color}/>)
          }}/>
      </Tab.Navigator>
    );
}


export default TabsBottomScreen;