import Constants from 'expo-constants';
import { getConfig, getSecureStore } from '../components/appconfig';

var token = null;
var endpoint = null;

async function initRequestHeader(){
    token = await getSecureStore("userToken");
    endpoint = await getConfig("endpoint");
}






async function GetRequest(uri){
    
    const lang = await getConfig("locale");
    var responseData = await fetch(endpoint + uri, {
        method: 'GET',
        headers: {
            Accept: 'text/plain',
            Authorization: 'Bearer ' + token,
            'Accept-Language': lang
        }
    })
    .then((response) => {
        if(response.status >= 400){
            throw response;
        }else{
            var contenttype = response.headers.get("Content-Type")
            return contenttype.indexOf("json") >= 0 ? response.json(): response.text();
        }  
    }).then((json) => { 
        //console.log(json);
        return json;
    }).catch((error) => { 
        //console.error(error);
        return error;
    }).finally(() => { });

    //console.log(responseData);
    return responseData;
}


async function PostRequest(uri, data){
    data = data === undefined ? {} : data;
    const lang = await getConfig("locale");

    var responseData = await fetch(endpoint + uri, {
        method: 'POST',
        headers: {
            Accept: 'text/plain',
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept-Language': lang
        },
        body: JSON.stringify(data)
    })
    .then((response) => {
        if(response.status >= 400){
            throw response;
        }else{
            var contenttype = response.headers.get("Content-Type")
            return contenttype.indexOf("json") >= 0 ? response.json(): response.text();
        }  
    }).then((json) => { 
        //console.log(json);
        return json;
    }).catch((error) => { 
        //console.error(error);
        return error;
    }).finally(() => { });

    //console.log(responseData);
    return responseData;
    return true;
}

async function PutRequest(uri, body){

}


initRequestHeader();

export { GetRequest, PostRequest, initRequestHeader };