import React from 'react';
import moment from 'moment';
import { ActivityIndicator, Button, Dimensions, FlatList, View, Text, ScrollView, SafeAreaView } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import Constants from 'expo-constants';

import { AuthContext} from '../components/authcontext';
import Logger from '../components/logger';
import { OrderContext } from '../components/ordercontext'
import { getConfig, getSecureStore } from '../components/appconfig';
import { i18n } from '../components/languages';
import { dpi, styles, theme } from '../components/styles';
import { GetRequest } from '../components/dataservice';

import { TableViewBasic } from './tableView';





function TablesScreen({ navigation,route }){
  const { id, title } = route.params;
  const [state, setState] = React.useState({ data: null, statusId: 0, outlets:{}, outletId: undefined, tableId: undefined, isFetching: false });
  const { signOut } = React.useContext(AuthContext);
  //const { openTable } = React.useContext(OrderContext);
  //Logger.log("tables.openTable", openTable);
  const isFocused = useIsFocused();
  Logger.set();

  const onRefresh = async () => {
    setState({...state, isFetching: true});
    await getOutlets();
  };

  const getOutlets = async () => {
    var response = await GetRequest(Constants.manifest.extra.tablesStatus + id);
    //Logger.log("response", response);
    if(response.status && response.status > 400){
      signOut();
    }else{
      Logger.log("response", response);
      var outlets = {};
      // set outlets count
      response.data.map((item) => outlets[item.flowId] === undefined ? outlets[item.flowId] = 1: outlets[item.flowId]++);
      //Logger.log("outlets", outlets);
      var outletId = outlets && Object.keys(outlets)[0];
      //Logger.log("outletId", outletId);
      //Logger.log("value", Object.keys(outlets)[0]);
      setState({...state, data:response.data, statusId: id, outlets: outlets, outletId: outletId, isFetching: false});
    }
  } 

  
  function bootstrapAsync(){
    //Logger.log("id", id);
    getOutlets();
  }

  

  React.useEffect(() =>{
    if(isFocused){
      bootstrapAsync();
      Logger.log("table.screen.useEffect");
    }
    
 },[isFocused]);





  const renderItem = ({ item }) => {
    //const backgroundColor = item.tableId === state.tableId ? "#999999" : "#EEEEEE";
    
    let sitedHours = 0;

    // ลูกค้านั่งนาน "#ffa07a"
    // เรียกเก็บเงินแล้ว "#66cdaa"
    // มีลูกค้า "#87cefa"
    // ว่าง "#b0c4de"
    let lang = "th";
    getConfig("local").then((data) => { lang = data; });

    if (item.status === "busy" 
      || item.status === "billing") {
        let sitedMinutes = 0;        
        const startTime = moment(item.openTime);
        const endTime = moment(Date.now());
        const diff = endTime.diff(startTime);
        const diffDuration = moment.duration(diff);

        let asMinutes = parseInt(diffDuration.asMinutes());
        //Logger.log("asMinutes=" + asMinutes);
        sitedHours = Math.floor(asMinutes/60);
        sitedMinutes = (asMinutes%60); // asMinutes - (sitedHousrs * 60)
        //Logger.log("sitedHours", [" + typeof(sitedHours) + "] = " + sitedHours );
        //Logger.log("sitedMinutes", [" + typeof(sitedMinutes) + "] = " + sitedMinutes );
        //Logger.log("lang", lang);

        item.sitedHours = sitedHours + "." + sitedMinutes + (lang == "th" ? " ชม." : " hrs."); //.toString("N2");
    }
    const backgroundColor = item.status === "blank"
      ? "#b0c4de"
      : item.status === "busy"
        ? sitedHours < 2
          ? "#87cefa"
          : "#ffa07a"
        : "#66cdaa";
    //item.sitedHours = sitedHours

    const color = item.tableId === state.tableId ? theme.colors.white : theme.colors.black;
    //Logger.log(state.outletId);


    return (state.outletId == item.flowId && <TableViewBasic item={item} onPress={() => onPress(item.tableId)} 
                  backgroundColor={{ backgroundColor }} textColor={{ color }}/>); 
  };

  /*
  const [refreshing, setRefreshing] = React.useState(false);
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  */

  return(state.data === null ?<View style={[styles.containerCenter]}>
      <View style={styles.column}>
        <ActivityIndicator size="large" color={theme.colors.secondary} />
        <Text style={styles.textCenter}>Loading</Text>
      </View>
    </View>:
    <SafeAreaView style={styles.container}>
      <FlatList renderItem={renderItem} keyExtractor={item => item.tableId} data={state.data} 
          numColumns={2} onRefresh={onRefresh} refreshing={state.isFetching}
          ListHeaderComponent={() => (<TableOutletControl id={state.statusId} data={state.outlets} 
            onPress={setOutletId} outletId={state.outletId} />)}
        /*ListFooterComponent={()=><TableStatusMenu />}*/ />
    </SafeAreaView>);


  function onPress(id) {
    Logger.log("onPress(id) called", id);
    var tables = state.data.filter(x => x.tableId === id);
    
    // check for have emp locked
    if (tables[0].lockedEmpId == ""
      || tables[0].lockedEmpId == null
      || tables[0].lockedEmpId == undefined) {
        
        Logger.log("go order");

        navigation.navigate('TableDetailScreen', { 
          id: id, 
          outletId: state.outletId, 
          table: tables && tables.length > 0 
            ? tables[0] 
            : undefined
        });
        //
        setState({...state, tableId: id});
    }    

    Logger.log("onPress(id) end");
  }


  function setOutletId(id){
    setState({...state, outletId: id});
  }


  /*
  return (<View>
    {state.data && 
     state.data.map((data) => (<TableViewBasic item={data} onPress={() => {} } key={data.tableId} />))}
  </View>);
  */

  /// send params
  // navigation.navigate('TableScreen', { param1: 'one', param2: 'two'});
  // get params
  /// https://reactnavigation.org/docs/params
  // function Details({navigation, route })
  // {
  //  const { id, title } = route.params;
  // }

}


function TableOutletControl(props){
  //Logger.log("props", props);
  //return (<View><Button title="FF" /></View>);
  return (<View style={[styles.rowCenter]}>{Object.keys(props.data).length > 0 && 
    Object.keys(props.data).map(item => (<View key={props.id+item} style={[(props.outletId == item && styles.buttonSecondary)]} >
      <Button title={"F"+item + " (" + props.data[item] +")"} onPress={() => props.onPress(item)} />
    </View>
  ))}</View>);
}

export default TablesScreen;