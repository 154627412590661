import React, { useRef } from 'react';
import { Alert, Platform } from 'react-native';
import Constants from 'expo-constants';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { i18n } from '../components/languages';
import { OrderContext } from '../components/ordercontext';
import { PostRequest, GetRequest } from '../components/dataservice';

import DrawerHome from '../components/drawerhome';
import HomeScreen from './homescreen';
import MenusScreen from './menusscreen';
import TablesScreen from './tablesscreen';
import OrdersScreen from './ordersscreen';
import TabsBottomScreen from './tabsbottomscreen';
import MeterialTopTabs from './meterialtoptabs';
import MeterialBottomTabs from './meterialbottomtabs';
import Dine from './dinein';
import FastFood from './fastfood';
import SwitchOutlet from './switchoutlet';
import DemoScreen from './demoscreen';
import Sellings from './sellings';
import ListMenus from './listmenus';
import DataFiles from './datafiles';
import Prints from './prints';
import Reports from './reports';
import TaxInvoices from './taxinvoices';
import MenuGroupings from './menuGroupings';
import MenuDetails from './menuDetails';
import TableDetailScreen from './tabledetailscreen';
import SearchScreen from './searchscreen';
import ModalDialog from '../screens/modalDialog';
import Logger from '../components/logger';


export default function IndexScreen({ navigation }){
    
    const modalDialogRef = useRef();
    const HomeStack = createNativeStackNavigator();


    React.useEffect(() => {
        // console.log((dpi > 1.5 ? "phone ": "not phone ")  + dpi + " dpi");
        if(state.busDate === null)
            checkBusDate();
    },[]);

    function checkBusDate() {
        console.log("checkBusDate() called");

        GetRequest("/api/BusDates").then((response) => {
            console.log("response=");
            console.log(response);

            if (response.status && response.status > 400) {
                signOut();
            } else {
                dispatch({action:'SET_BUSDATE', busDate: response});
                if (response.error !== null) {                    
                    modalDialogRef.current.show(response.error);
                }
            }
        });

        console.log("checkBusDate() end");
    }

    const [state, dispatch] = React.useReducer(
        (prevState, action) => {
        switch (action.type) {
            case 'TABLE_OPEN':
                return {
                    ...prevState,
                    table: action.table,
                    status: action.status,
                    outletId: action.outletId
                };
            case 'TABLE_CLOSE':
                return {
                    ...prevState,
                    message: null,
                    status: "blank",
                    outletId: null,
                    table: null,
                };
            case 'TABLE_UPDATE':
                return {
                    ...prevState,
                    table: action.table
                };
            case 'TABLE_ORDER':
                return {
                    ...prevState,
                    table: action.table,
                    status: action.status,
                    outletId: action.outletId
                };
            case 'TABLE_BILL':
                return {
                    ...prevState,
                    table: action.table,
                    status: action.status,
                    outletId: action.outletId
                };
            case 'TABLE_RETRIEVE':
                return {
                    ...prevState,
                };
            case 'UPDATE_MESSAGE':
                return {
                    ...prevState,
                    message: action.message
                };
            case 'UPDATE_ORDER':
                return {
                    ...prevState,
                    table: action.table
                };
            case  'SET_BUSDATE':
                return {
                    ...prevState,
                    busDate: action.busDate
                }
            default: return prevState;
        }
    },{
        message: null,
        status: "blank",
        outletId: null,
        table: null,
        busDate: null
    });


    const tableValue = React.useMemo(() => ({
        openTable: async (id) => {
            var response = await PostRequest(Constants.manifest.extra.tablesOpen + id);
            //console.log(response);
    
            if(response.status && response.status > 400){
                //signOut();
            }else{
                // console.log(response.error);
                if(response.error === undefined){
                    // console.log(response.data);
                    dispatch({type:"TABLE_OPEN", table: response.data, id: id});

                    return response.data;
                }else{
                    var messages = "";
                    response.error.messages.map((message) => messages += message +"\n");
                    dispatch({type:"UPDATE_MESSAGE", message: messages});
                    return messages;
                }
            }
        },

        updateTable: (table) => {
            dispatch({type:"UPDATE_TABLE", table: table});
        },

        billTable: async (id, table) => {
            var response = await PostRequest(Constants.manifest.extra.tablesBill + id, table);
            //console.log(response);
            if(response.status && response.status > 400){
                signOut();
            }else{
                if(response.error === undefined){
                    //console.log(response.data);
                    dispatch({type: 'TABLE_BILL', table: response.data});
                }else{
                    var messages = "";
                    response.error.messages.map((message) => messages += message +"\n");
                    
                    if(Platform.OS === 'web'){
                        alert(response.error.code + "\n\r" + messages);
                    }else{
                        Alert.alert(response.error.code, messages,[{text: "OK", onPress: () => {}}]);
                    }
                    dispatch({type:"UPDATE_MESSAGE", message: messages});
                }
            }
        },

        closeTable: async (id) => {
            var response = await PostRequest(Constants.manifest.extra.tablesClose + id);
        },

        checkTable: async (id, table) => {   
            var response = await PostRequest(Constants.manifest.extra.tablesCheck + id, table);
            //console.log(response);
            if(response.status && response.status > 400){
                signOut();
            }else{
                if(response.error === undefined){
                    //console.log("TABLE_UPDATE", response.data);
                    dispatch({type: 'TABLE_UPDATE', table: response.data});
                }else{
                    var messages = "";
                    response.error.messages.map((message) => messages += message +"\n");
                    
                    if(Platform.OS === 'web'){
                        alert(response.error.code + "\n\r" + messages);
                    }else{
                        Alert.alert(response.error.code, messages,[{text: "OK", onPress: () => {}}]);
                    }
                    dispatch({type:"UPDATE_MESSAGE", message: messages});
                }
            }
        },

        getTable: () => {
            return state.table;
        },

        orderTable: async (id, table) => {
            var response = await PostRequest(Constants.manifest.extra.tablesOrder + id, table);
 
            //console.log("orderTable", response);
            if(response.status && response.status > 400){
                signOut();
            }else{
                if(response.error === undefined){
                    //console.log(response.data);
                    let newOrder = response.data.items.filter(({orderTime}) => orderTime === undefined);
                    //console.log("orderTable newOrder", newOrder.length);
                    dispatch({type: 'TABLE_UPDATE', table: response.data});
                    return response.data;
                }else{
                    var messages = "";
                    response.error.messages.map((message) => messages += message +"\n");
                    
                    if(Platform.OS === 'web'){
                        alert(response.error.code + "\n\r" + messages);
                    }else{
                        Alert.alert(response.error.code, messages,[{text: "OK", onPress: () => {}}]);
                    }

                    dispatch({type:"UPDATE_MESSAGE", message: messages});
                    return messages;
                }
            }
        },

        retrieveTable: async (id) => {
            let response = await PostRequest(Constants.manifest.extra.tablesRetrieve + id);

            if(typeof response === 'string'){
                dispatch({type:"UPDATE_MESSAGE", message: response});
            }else{
                dispatch({type:"UPDATE_ORDER", table: response.data});
            }

            if(response.status && response.status > 400){
                signOut();
            }else{
                if(response.error === undefined){
                    //console.log(response.data);
                    dispatch({type: 'TABLE_UPDATE', table: response.data});
                }else{
                    var messages = "";
                    response.error.messages.map((message) => messages += message +"\n");
                    
                    if(Platform.OS === 'web'){
                        alert(response.error.code + "\n\r" + messages);
                    }else{
                        Alert.alert(response.error.code, messages,[{text: "OK", onPress: () => {}}]);
                    }

                    dispatch({type:"UPDATE_MESSAGE", message: messages});
                }
            }
        },

        addOrderItem: async (item) => {
            let table = state.table;
            
            table.items.push(item);
            //console.log("add order item", item);
            //console.log("table add order item", table);

            let response = await PostRequest(Constants.manifest.extra.tablesCheck + table.tableId, table);

            if(typeof response === 'string'){
                dispatch({type:"UPDATE_MESSAGE", message: response});
            }else{
                //console.log("TABLE_UPDATE", response.data)
                dispatch({type:"TABLE_UPDATE", table: response.data});
            }
        },

        removeOrderItem: async (removeItem) => {
            let table = state.table;
            //console.log(removeItem);
            // TODO: remove item
            table.items = table.items.filter((item) => item.indexNo !== removeItem.indexNo);
            //console.log("remove table", table);
            //console.log("table.tableId", table.tableId);
            let response = await PostRequest(Constants.manifest.extra.tablesCheck + table.tableId, table);
            //console.log("remove table response", response);
            if(typeof response === 'string'){
                //console.log("UPDATE_MESSAGE", response);
                dispatch({type:"UPDATE_MESSAGE", message: response});
            }else{
                //console.log("TABLE_UPDATE", response);
                dispatch({type:"TABLE_UPDATE", table: response.data});
            }
            
        },




    }),[state]);


    return (<OrderContext.Provider value={tableValue}>
    <HomeStack.Navigator screenOptions={{ headerTitleAlign: 'center', headerShown: false }}>
        <HomeStack.Screen name="DrawerHome"   component={DrawerHome} options={{ headerShown: false }} />
        <HomeStack.Screen name="HomeScreen"   component={HomeScreen}   options={{title: i18n.t("HOME"), headerShown:true}}  />
        <HomeStack.Screen name="Dine" component={Dine} options={{title: i18n.t('F301013'), headerShown:true}} />
        <HomeStack.Screen name="FastFood" component={FastFood} options={{title: i18n.t('F301014'), headerShown:true}} />
        <HomeStack.Screen name="SwitchOutlet" component={SwitchOutlet} options={{title: i18n.t('F401035'), headerShown:true}} />
        <HomeStack.Screen name="MenuGroupings" component={MenuGroupings} options={{title: i18n.t('F101012'), headerShown:true}} />
        <HomeStack.Screen name="MenuDetails" component={MenuDetails} options={{headerShown:true}} />
        <HomeStack.Screen name="TableDetailScreen" component={TableDetailScreen} options={{title: "Table", headerShown:true}} />
        <HomeStack.Screen name="SearchScreen" component={SearchScreen} />

        <HomeStack.Screen name="Sellings" component={Sellings} options={{title: i18n.t('SELLINGS'), headerShown:true}} />
        <HomeStack.Screen name="ListMenus" component={ListMenus} options={{title: i18n.t('LIST_MENU'), headerShown:true}} />
        <HomeStack.Screen name="Prints" component={Prints} options={{title: i18n.t('PRINTS'), headerShown:true}} />
        <HomeStack.Screen name="DataFiles" component={DataFiles} options={{title: i18n.t('DATA_FILES'), headerShown:true}} />
        <HomeStack.Screen name="Reports" component={Reports} options={{title: i18n.t('REPORTS'), headerShown:true}} />
        <HomeStack.Screen name="TaxInvoices" component={TaxInvoices} options={{title: i18n.t('TAX_INVOICES'), headerShown:true}} />
            

        <HomeStack.Screen name="TablesScreen" component={TablesScreen} options={{title: i18n.t("TABLES"), headerShown:true}} />
        <HomeStack.Screen name="MenusScreen"  component={MenusScreen}  options={{title: i18n.t("MENUS"), headerShown:true}} />
        <HomeStack.Screen name="OrdersScreen" component={OrdersScreen} options={{title: i18n.t("ORDERS"), headerShown:true}} />
        <HomeStack.Screen name="TabsBottomScreen" component={TabsBottomScreen} options={{title: "Tabs", headerShown:true}} />
        <HomeStack.Screen name="MeterialTopTabs" component={MeterialTopTabs} options={{title: "Meterial Top Tabs", headerShown:true}} />
        <HomeStack.Screen name="MeterialBottomTabs" component={MeterialBottomTabs} options={{title: "Meterial Bottom Tabs", headerShown:true}} />
            
        <HomeStack.Screen name="DemoScreen" component={DemoScreen} options={{title: "Demo Screen", headerShown:true}} />
            
    </HomeStack.Navigator>
    <ModalDialog ref={modalDialogRef} />
    </OrderContext.Provider>);
};