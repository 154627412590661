
import React from 'react';
import { Dimensions, Text, View, useWindowDimensions } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { getHeaderTitle } from '@react-navigation/elements';
import Constants from 'expo-constants';
import { i18n } from './languages';


import HomeScreen from '../screens/homescreen';
import NotificationsScreen from '../screens/notificationsscreen';
import ProfileScreen from '../screens/profilescreen';
import SettingsScreen from '../screens/settingsscreen';
import Sellings from '../screens/sellings';
import Reports from '../screens/reports';
import DataFiles from '../screens/datafiles';
import TaxInvoices from '../screens/taxinvoices';
import SwitchOutlet from '../screens/switchoutlet';


const Drawer = createDrawerNavigator();

/// https://reactnavigation.org/docs/drawer-based-navigation/
/// https://reactnavigation.org/docs/elements#header

function DrawerHome() {
  const dimensions = useWindowDimensions();

  const isLargeScreen = dimensions.width >= 768;

  const header= ({ navigation, route, options }) => {
    const title = getHeaderTitle(options, route.name);
  
    return <MyHeader title={title} style={options.headerStyle} />;
  };

    /// https://reactnavigation.org/docs/drawer-navigator/#header-related-options
    return (
      <Drawer.Navigator //defaultStatus="open" 
        drawerLabel="Drawer"
        header={header}
        initialRouteName="HomeScreen"
        screenOptions={{
          drawerStyle: {  width: 240  }, 
          //drawerType: isLargeScreen ? 'permanent' : 'back',
          //drawerStyle: isLargeScreen ? null : { width: 240 },
          //overlayColor: 0
          headerTitleAlign: 'center'
        }}>

        <Drawer.Screen name="Home" component={HomeScreen} options={{title:i18n.t("HOME")}} />
        <Drawer.Screen name="SwitchOutlet" component={SwitchOutlet} options={{title:i18n.t("F401035")}} />
        {/*
        <Drawer.Screen name="Sellings" component={Sellings} options={{title:i18n.t("SELLINGS")}} />
        <Drawer.Screen name="Reports" component={Reports} options={{title:i18n.t("REPORTS")}} />
        <Drawer.Screen name="DataFiles" component={DataFiles} options={{title:i18n.t("DATA_FILES")}} />
        <Drawer.Screen name="TaxInvoices" component={TaxInvoices} options={{title:i18n.t("TAX_INVOICES")}} />

        <Drawer.Screen name="Notifications" component={NotificationsScreen} options={{title:i18n.t("NOTIFICATION")}} />
        */}
        <Drawer.Screen name="Profile" component={ProfileScreen} options={{title:i18n.t("PROFILE")}} />
        <Drawer.Screen name="Settings" component={SettingsScreen} options={{title:i18n.t("SETTINGS")  
                      +" V."+ Constants.manifest.version }} />
      </Drawer.Navigator>
    );
}



export default DrawerHome;