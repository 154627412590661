import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import MenusScreen from './menusscreen';
import OrdersScreen from './ordersscreen';
import TablesScreen from './tablesscreen';

const Tab = createMaterialTopTabNavigator();


function MeterialTopTabs({ navigation }){
  return (
    <Tab.Navigator>
      <Tab.Screen name="Tables" component={TablesScreen} />
      <Tab.Screen name="Menus" component={MenusScreen} />
    </Tab.Navigator>
  );
}


export default MeterialTopTabs;