import React from 'react';
import {Alert, Button, Image, StyleSheet, Text,TextInput, View } from 'react-native';
import Constants from 'expo-constants';
import { getConfig, setConfig } from '../components/appconfig';
import { AuthContext } from '../components/authcontext';
import { i18n } from '../components/languages';

export default function AppConfigScreen({ navigation }){
    const { getAppConfig, setAppConfig } = React.useContext(AuthContext);

    const[endpoint, setEndpoint] = React.useState('');
    const[stationId, setStationId] = React.useState('');

    const readData = async () => {
        getConfig("endpoint").then((value) => {if(value !== null){setEndpoint(value)}});
        getConfig("stationId").then((value) => {if(value !== null){setStationId(value)}});
    };

    
    React.useEffect(() => {
        readData(); 
    },[]);


    return (
        <View>
            <View style={styles.row} >
                <Image style={styles.logo} source={require('../assets/propos-icon.png')} />
            </View>
            <View style={styles.row}><Text>version {Constants.manifest.version}</Text></View>
            <View>
                <Text style={styles.text}>EndPoint</Text>
                <TextInput placeholder="apiEndpoint" style={styles.textInput} defaultValue={endpoint} onChangeText={(value) => setEndpoint(value)}/>
            </View>
            <View>
                <Text style={styles.text}>Station ID</Text>
                <TextInput placeholder="StationID" style={styles.textInput} defaultValue={stationId} onChangeText={(value) => setStationId(value)}/>
            </View>
            <View style={styles.marginVertical}>
                <Button style={styles.button} title={i18n.t('save')} onPress={() => {setAppConfig({endpoint, stationId})}} />
            </View>
            <View style={styles.marginVertical}>
                <Button style={styles.button} title={i18n.t('close')} color="#888888" onPress={() => navigation.popToTop()} />
            </View>
        </View>
    )
}





const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    logo: {
        width: 64,
        height: 64,
        marginHorizontal: 16,
        marginVertical: 8,
    },
    row: {
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexButton: {
        padding: 8,
    },
    flex: {
        flex:1,
        alignItems: 'stretch'
    },

    text:{
        marginHorizontal: 4,
        marginTop: 8
    },
    textInput: {
        borderColor: 'gray', 
        borderWidth: 1,
        backgroundColor: 'white',
        padding: 8,
        margin: 4
    },
    button: {
        flex:2,
        padding: 16,
        margin: 16,
        height: 64,
    },
    marginVertical:{
        marginVertical: 4,
    },
    ImageSplashScreen: {
        flex: 1,
        justifyContent: "flex-end",
        minHeight: 500,
        height: '100%'
    }

});