import { StyleSheet, Appearance, useColorScheme , PixelRatio, PlatformColor } from "react-native";
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import { Surface } from "react-native-paper";
import { block } from "react-native-reanimated";

/// https://docs.expo.dev/guides/color-schemes/

const dpi = PixelRatio.get();
var schemeColor = "light"; //[light, dark, null]


/// https://reactnavigation.org/docs/themes/
const _theme = schemeColor === "light" ? DefaultTheme: DarkTheme;
const theme = { ..._theme,
  // colors: { primary, background, card, text, border, notification },
  colors: { 
    /// https://material.io/design/color/the-color-system.html#color-theme-creation
    ..._theme.colors,
    primaryVariant: "",
    secondary: "#AAAAAA",
    secondaryVariant: "",
    tertiary: "#888888",
    blue: "#0d6efd",
    indigo: "#6610f2",
    purple: "#6f42c1",
    pink: "#d63384",
    red: "#dc3545",
    orange: "#fd7e14",
    yellow: "#ffc107",
    green: "#198754",
    teal: "#20c997",
    black: "#000000",
    white: "#FFFFFF",
    brightcyan: "#17a2b8",
    cyan: "#0dcaf0",
    gray: "#adb5bd",

    surface: "#EEEEEE",
    offSurface: "rgba(255, 255, 255, .9)",
    error: "#B00020",

    onPrimery: "",
    onSecondary: "",
    onBackground: "",
    onSurface: ""
    

  },
  fontSize: 18

}
  

function switchColorScheme(){
  //backgroundColor = SystemUI.getBackgroundColorAsync();
  schemeColor = useColorScheme();

  //console.log(backgroundColor);
  return schemeColor;
};




const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    //color: theme.colors.text,
    //backgroundColor: theme.colors.background,
    //alignContent: 'center',
    //alignItems: 'center',
    //alignSelf: 'space-between',
    //justifyContent: 'flex-start',

    height: '100%',
    width: '100%'
  },
  containerCenter: {
    flex: 1,
    flexDirection: "column",
    alignSelf: 'center',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  logo: {
    width: 64,
    height: 64,
    marginHorizontal: 16,
    marginVertical: 8,
  },
  title: {
    fontSize: theme.fontSize*1.5,
  },


  tableBlank: {
    width: 63,
    height: 40,
  },
  tableBilling: {
    width: 40,
    height: 40,
  },
  tableLocked: {
    width: 20,
    height: 20,
  },
  tableView: {
    minHeight: 75,
  },


  column: {
    flexDirection: 'column',
    //alignContent: 'center'
  },
  columnReverse: { 
    flexDirection: 'column-reverse' 
  },

  row: { 
    flexDirection: "row", 
    flexWrap: "wrap", 
    alignItems: 'stretch', 
    alignContent: 'stretch',
    //justifyContent: 'center',
    minHeight: theme.fontSize* 1.2
  },
  rowReverse: { 
    flexDirection: 'row-reverse' 
  },
  rowCenter: {
    flexDirection: "row",
    //alignItems:'center', 
    //alignContent: 'center',
    justifyContent: 'center',

    //flexWrap: "wrap",
  },
  rowFull: { 
    flexWrap: "nowrap",
    alignSelf: "stretch",
    alignItems : 'stretch',
    justifyContent: 'center',
    flexDirection: "row",
    alignContent: "space-between",

  },


  menu: { flex: .25 },
  menuCenter: { 
    /*flex: 'auto',
    alignContent: 'center',
    justifyContent: 'center',*/
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.colors.border,
    padding: 6,
    margin: 1,
    minWidth: 100,
  },
  menuLeft: {
    width: "30%",
    maxWidth: 300
  },  


  content: { 
    flex: .75,
    paddingHorizontal: 10
  },



  flex: {
      flex:1,
      alignItems: 'stretch'
  },

  
  item: {

    backgroundColor: theme.colors.card,
    borderColor: theme.colors.border,
    borderWidth: 1,
    padding: 4,
    marginVertical: 1,
    marginHorizontal: 1,
  },
  itemSmall: {
    backgroundColor: theme.colors.card,
    borderColor: theme.colors.border,
    borderWidth: 1,
    padding: 6,
    marginVertical: 1,
    marginHorizontal: 2,
  },
  itemList: {
    backgroundColor: theme.colors.card,
    borderColor: theme.colors.border,
    borderWidth: 1,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginVertical: 2,
    marginHorizontal: 2,
    //paddingHorizontal: 2,
  },
  itemFront: {
    flex: 1,
    alignItems: 'flex-start',

    flexBasis: 50,
    flexShrink: 0,
    flexGrow: 0
  },
  itemCenter: {
    alignItems: 'flex-start',

    flexBasis: 150,
    flexShrink: 1,
    flexGrow: 1
  },
  itemContent: {
    alignItems: 'flex-start',

    flexBasis: 'auto',
    flexShrink: 1,
    flexGrow: 1
  },
  itemBack:{
    flex: 1,
    fontWeight: "bold",
    alignItems: 'flex-end',
    textAlign: "right",
    flexBasis: 50,
    flexShrink: 0,
    flexGrow: 0
  },


  imageFull:{
    height: 80,
    width: 80, 
  },
  image: {
    height: 40,
    width: 40, 
  },


  h1: { 
    fontSize: theme.fontSize*3,
    fontWeight: 'bold'
  },
  h2: { 
    fontSize: theme.fontSize*2.5,
    fontWeight: 'bold'
  },
  h3: { 
    fontSize: theme.fontSize*2,
    fontWeight: 'bold'
  },
  h4: { 
    fontSize: theme.fontSize*1.75,
    fontWeight: 'bold'
  },
  h5: { 
    fontSize: theme.fontSize*1.5,
    fontWeight: 'bold'
  },
  h6: { 
    fontSize: theme.fontSize*1.25,
  },
  text: {
    fontSize: theme.fontSize
  },
  textLabel: {
    flex: 1,
    alignContent:'stretch'
  },
  textHeader: {
    fontSize: theme.fontSize*2,
    fontWeight: "bold"
  },
  textCenter: {
    textAlign: 'center'
  },
  textCenterImage: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: theme.colors.white,
    textShadowColor: theme.colors.white,
    textShadowOffset:{width: 0, height: 0},
    textShadowRadius: 10,
    minWidth: 40,
    minHeight: 40,
    textAlign: "center"
  },
  textRight: {
    textAlign: 'right'
  },
  textSubtitle:{ 
    color: theme.colors.secondary // "#888888" 
  },
  textInput: {
    alignContent:'stretch',
    borderColor: theme.colors.border, 
    borderWidth: 1,
    backgroundColor: theme.colors.card,
    padding: 8,
    margin: 4
      
  },
  textWarning: {
    color: 'orange'
  },
  textButton: {
    color: schemeColor === "light" ? theme.colors.white: theme.colors.black,
    fontWeight: 'bold',
    margin: 5,
    textAlign: 'center',
  },


  headerIcon: {
    marginVertical:3,
    marginHorizontal:11
  },

  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.offSurface
  },
  modalView: {
    flex: 1,
    margin: theme.fontSize*1,
    marginTop: theme.fontSize*3,
    backgroundColor: theme.colors.surface,
    borderRadius: theme.fontSize/3,
    padding: theme.fontSize,
    alignItems: 'center',
    shadowColor: theme.colors.border,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "90%",
 
  },


  marginVertical:{
    marginVertical: 2,
  },


  button: {
    alignItems:'center',
    alignContent: 'center',
    borderRadius: 5,

    paddingVertical: 8,
    paddingHorizontal: 20,
    elevation: 2,
    margin: 4,
    padding: 2,
    minHeight: theme.fontSize * 2,
  },
  buttonFlex: {
    flex:1,
    padding: 8,
    margin: 8,
  },
  buttonFull: {
    flex: 1,
    width: "auto",
  },
  buttonPrimary: {
    alignContent: 'center',
    backgroundColor: theme.colors.primary,
    borderRadius: 5,
    color: theme.colors.white,
    padding: 10,
    fontWeight: "bold",
    textTransform: 'uppercase'
  },
  buttonSecondary: {
    backgroundColor: theme.colors.secondary,
    color: theme.colors.tertiary
  },
  buttonWarning: {
    backgroundColor: theme.colors.yellow,
    color: theme.colors.white
  },
  buttonOpen: {
    backgroundColor: theme.colors.primary 
  },
  buttonClose: {
    backgroundColor: theme.colors.secondary 
  },
  buttonSquare: {
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 5,
    fontWeight: "bold",
    
    minHeight: 42,
    minWidth: 42
  },
  buttonAuto:{
    flex: 1,
    flexBasis: 100,
    flexGrow: 1,
    flexShrink: 1,
    
    borderRadius: 10,
    borderWidth: 1,
    alignContent: 'space-around',
    alignSelf: 'stretch',
    textAlign: 'center',

    fontWeight: 'bold',
    fontSize: theme.fontSize*2,

    paddingVertical: 16,
    paddingHorizontal:8,
    
    minHeight: 42,
    height: 42,
    minWidth: '50%',
    width: '50%',
  },
  buttonOption:{
    flex: 1,
    minHeight: 40,
    minWidth: 100
  },

  
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  inputNumber:{
    margin: 5,
    borderWidth: 1,
    padding: 5,
    textAlign:"right"
  },
  inputNumberCenter:{
    borderRadius: 5,
    height: 40, width: 40, 
    margin: 12,
    borderWidth: 1,
    padding: 10,
    textAlign:"center"
  },


  ImageSplashScreen: {
    flex: 1,
    justifyContent: "flex-end",
    minHeight: 500,
    height: '100%'
  },



  height20pc: {
    height: '20%'
  },
  height80pc: { 
    height: '80%', 
  },
  height90pc: { 
    height: '90%', 
  },
  


  width10pc: { 
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: theme.fontSize*1.5,
    width: '10%'
  },
  width20pc: { 
    flexGrow: 1,
    flexShrink: 0,
    width: '20%'
  },
  width30pc: { 
    flexGrow: 1,
    flexShrink: 0,
    width: '30%'
  },
  width40pc: {
    flexGrow: 1,
    flexShrink: 0,
    width: '40%'
  },
  width50pc: {
    flexGrow: 1,
    flexShrink: 0,
    width: '50%'
  },
  width60pc: {
    flexGrow: 1,
    flexShrink: 0,
    width: '60%'
  },
  width70pc: { 
    flexGrow: 1,
    flexShrink: 0,
    width: '70%'},
  width90pc: { 
    flexGrow: 1,
    flexShrink: 0,
    width:'90%'},
  width100pc: { 
    flexGrow: 1,
    flexShrink: 0,
    width:'100%'},
  widthAuto: { 
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "auto",
    width: 'auto' ,
  },



  marginTopStep1:{
    marginTop: 20,
  },
  topCenter: {
    /*flex: 'auto',*/
    justifyContent: 'center'
  },  


  innerTopLeft: {
    flex: 1,
    alignItems: 'flex-start',

  },
  innnerTopCenter: {
    flex: 1,
    alignItems: 'center',

  },
  innerTopRight: { 
    flex: 1,
    alignItems: 'flex-end',

  },
  innerAlignItems: { 
    alignItems: 'stretch',
    alignItems: 'baseline'
  },
  innerBottomLeft: {
    flex: 3,
    alignItems: 'flex-start',

   },
  innerBottomCenter: { 
    alignItems: 'center',
  },
  innerBottomRight: { 
    flex: 1,
    alignItems: 'flex-end',
    color: theme.colors.secondary
  },



  scrollView: {
    backgroundColor: theme.colors.card // '#F8F8F8',
  },
});
  
  
export { dpi, styles, theme, switchColorScheme };