// https://api.travelpro.in.th/api/FunctionLists/GetTranslations

//import * as Localization from 'expo-localization';
import { Alert, Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import i18n from 'i18n-js';

import { getConfig, setConfig, getSecureStore } from './appconfig';


// Set the key-value pairs for the different languages you want to support.
var translations = {
    en: { welcome: 'Hello', name: 'name', version: "version",
        username: "Username", password: "Password", confirmPassword: "Confirm Password", 
        loading: "loading...", save: "SAVE", close: "CLOSE", logout: "LOGOUT",
        SIGN_IN: "SIGN IN", SIGN_UP: "SIGN UP", back: "Back", register: "REGISTER", 
        HOME: "HOME", TABLES: "TABLES", TABLE: "TABLE", MENUS: "MENUS", ORDERS: "ORDERS", 
        SEARCH: "SEARCH", NOTIFICATION: "NOTIFICATIONS",
        PROFILE: "PROFILE", SETTINGS: "SETTINGS", DINE_IN: "DINE IN", FAST_FOOD: "FASTFOOD", 
        SWITCH_OUTLET: "SWITCH OUTLET", SELLINGS: "SELLINGS", REPORTS: "REPORTS", LIST_MENU: "LIST MENU",
        PRINTS: "PRINT", MENU_GROUPINGS: "MENU GROUPINGS",
        TAX_INVOICES: "TAX INVOICES", DATA_FILES: "DATA FILES", MENU_ITEMS: "MENU ITENS",
        F001001: "Login", OPTIONS: "options", ADDITION: "addition", ADD: "Add", OPEN_MODIFIER: "Open Modifier"
    },
    th: { welcome: "สวัสดี", name: 'ชื่อ', version: "เวอร์ชั่น",
        username: "ชื่อผู้ใช้งาน",  password: "รหัสผ่าน", confirmPassword: "ยืนยันรหัสผ่าน", 
        loading: "กำลังโหลด...",  save: "บันทึก", close: "ปิด", logout: "ออกโปรแกรม",
        SIGN_IN: "เข้าระบบ", SIGN_UP: "ลงทะเบียน", back:"กลับ", register:"ลงทะเบียน", 
        HOME: "หน้าหลัก", TABLES:"โต๊ะ", TABLE: "โต๊ะ", MENUS: "เมนู", ORDERS: "สั่งอาหาร", 
        SEARCH: "ค้นหา", NOTIFICATION: "แจ้งเตือน",
        PROFILE: "ข้อมูลผู้ใช้", SETTINGS: "การตั้งค่า", DINE_IN: "DINE IN", FAST_FOOD: "FASTFOOD", 
        SWITCH_OUTLET: "SWITCH OUTLET", SELLINGS: "ปิดเปิด การขาย & ชิฟ", REPORTS: "รายงานขาย & รายการขาย", LIST_MENU: "รายการเมนูที่ขาย",
        PRINTS: "พิมพ์", MENU_GROUPINGS: "เมนูหลัก",
        TAX_INVOICES: "ใบกำกับภาษี", DATA_FILES: "แฟ้มข้อมูล", MENU_ITEMS: "รายการเมนูที่ขาย",
        F001001: "เข้าระบบ", OPTIONS: "เพิ่มเติม", ADDITION: "คำสั่งพิเศษ", ADD: "เพิ่ม", OPEN_MODIFIER: "พิมพ์คำสั่งพิเศษ"
    }
};

// When a value is missing from a language it'll fallback to another language with the key present.
function translates(){
    //console.log("translates");
    //console.log(translations);
    initLanguage().then((value) => { 
        //console.log("translates init");
        translations = value;
        i18n.translations = translations; 
        return translates;
    });
    //console.log(translations);
    //console.log("translated");
    return translations;
}

i18n.fallbacks = true;
i18n.translations = translations;//
translates();






async function initLanguage(){
    
    var text = await getConfig("translations");
    //console.log(text);
    if(text !== undefined && text !== null){
        translations = JSON.parse(text);
    }

    //console.log(translations);
    if(text === null || text.indexOf("F101000") === -1) {
        getLanguage();
    }
    //console.log(translations);

    var locale = await getConfig("locale");
    i18n.locale = locale === undefined || locale === null ? Constants.manifest.extra.language: locale;

    //console.log("initLanguage");
    //console.log(translations);
    return translations;
}


// Set the locale once at the beginning of your app.








async function getLanguage(){
    //const token = await getSecureStore("userToken");
    const uri = await getConfig("endpoint") ;
    if(uri !== null){
        fetch(uri+ Constants.manifest.extra.getLanguage, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
                //Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            if(response.status >= 400){
                throw response;
            }else{
                var contenttype = response.headers.get("Content-Type")
                return contenttype.indexOf("json") >= 0 ? response.json(): response.text();
            }
            
        }).then((json) => { 
            /// https://stackoverflow.com/questions/21450060/how-to-join-two-javascript-objects-without-using-jquery
            //console.log(i18n.translations);
            //console.log(json);
            if (json != null && json != undefined) {
                var en = { };
                Object.assign(en, i18n.translations.en, json.en);

                var th = { };
                Object.assign(th, i18n.translations.th, json.th);

                translations = { en: en, th: th};
                i18n.translations = translations;
                //console.log(translations);
                setConfig("translations", JSON.stringify(translations));

        
                setLanguage(i18n.locale);

                // alert("Updated language");
            }
        }).catch((rejected) => {
            console.error(rejected);
            return rejected;
        }).catch((error) => { 
            //console.error("error=");
            //console.error(error);
            alert(error.statusText + ": Can't Using GetTranslations service");
            return error;
        }).finally(() => { });
    }else{
        setConfig("translations");
    }
}

/// https://docs.expo.dev/versions/v44.0.0/sdk/localization/
/// https://stackoverflow.com/questions/57330046/how-to-restart-app-react-native-and-expo
/// https://docs.expo.dev/versions/v44.0.0/sdk/updates/
async function setLanguage(locale){
    //console.log("localze " + localze);
    //console.log("language " + Constants.manifest.extra.language);
    setConfig("locale", locale);

    //await getLanguage();
    //console.log(Localization.locale);
    //i18n.localze = localze === undefined ? Constants.manifest.extra.language : localze;

    //Restart();
    if(Platform.OS === "web"){
        location.reload();
    }else{
        await Updates.reloadAsync();
    }
    
    //alert(i18n.t('reloadMsg'+localze));
}


export {initLanguage, getLanguage, setLanguage, i18n};