import {Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';


async function getConfig(key){
    try{
        const value = await AsyncStorage.getItem(key)
        return value;
    }catch(e){
        // error reading value
        return e;
    }
}

/// https://docs.expo.dev/versions/v44.0.0/sdk/async-storage/
/// https://react-native-async-storage.github.io/async-storage/docs/usage/
async function setConfig(key, value){
    //console.log(key + "= " + value);
    try{
        if(value === null || value === undefined){
            await AsyncStorage.removeItem(key);
        }else {
            await AsyncStorage.setItem(key, value).then((error, res) => {
                //console.log(error);
                //console.log(res);
            }).catch((error) => console.log(error));
        }
        
        return true;
    }catch(e){
        // saving error
        console.error(e);
    }
}


/// 
/// https://docs.expo.dev/versions/v44.0.0/sdk/securestore/
/// https://docs.expo.dev/versions/latest/sdk/securestore/
async function setSecureStore(key, value) {
  //console.log("setSecureStore('"+ key + "', '" + value + "')");

    if(Platform.OS === 'web'){
      if(value === null)
        await AsyncStorage.removeItem(key);
      else
        await AsyncStorage.setItem(key, value)
    }else{
      if(value === null)
        await SecureStore.deleteItemAsync(key);
      else
        await SecureStore.setItemAsync(key, value).then((value) => console.log(value));
    }
    
  }
  
  async function getSecureStore(key) {
    let result = null;
    if(Platform.OS === 'web'){
      result = await AsyncStorage.getItem(key);
    }else{
      result = await SecureStore.getItemAsync(key);
    }

    //console.log("getSecureStore('"+ key + "', '" + result + "')");

    return result ?? null;
  }


export { getConfig, setConfig, getSecureStore, setSecureStore};