import React from 'react';
import { Button, Dimensions, PixelRatio, StatusBar, StyleSheet, Text, View, SafeAreaView } from 'react-native';

import { AuthContext } from '../components/authcontext';
import { styles,dpi } from '../components/styles';
import { i18n } from '../components/languages';

import HomeMenu from './homemenu';







export default function HomeScreen({ navigation }){
  const { signOut } = React.useContext(AuthContext);

  //const isDrawerOpen = useDrawerStatus() === 'open';
  //const window = Dimensions.get('window');
  const screen = Dimensions.get('screen');



  React.useLayoutEffect(() => {
    navigation.setOptions({
        ///headerLeft: () => (<Button onPress={() => {}} title="MENU" />),
        headerRight: () => (<Button onPress={signOut} title={i18n.t("logout")} />)
    })
    //console.log(window);
    //console.log("window " + window.width +" x "+ window.height);
    //console.log("screen " + screen.width +" x "+ screen.height);
  });




  if(dpi <= 1.5 && (screen.width / screen.height > 1)){
    return (
      <SafeAreaView style={styles.row}>
        <View style={styles.menuLeft}>
          <HomeMenu navigation={navigation} />
        </View>
        <View style={styles.content}>
          <Text>BIG SCREEN</Text>
        </View>
      </SafeAreaView>
    );
  }else{
    return (<HomeMenu navigation={navigation} />);
  }

}


