import React from 'react';
import { View, Text } from 'react-native';
import { styles, dpi } from '../components/styles';
import { i18n } from '../components/languages';

import HomeMenu from './homemenu';


function getMenus(){
    
}



function Menus({ navigation }){






    if(dpi <= 1.5 && (screen.width / screen.height > 1)){
        return (
          <View style={styles.row}>
            <View style={styles.menu}>
              <HomeMenu navigation={navigation} />
            </View>
            <View style={styles.content}>
              <Text>{i18n.t('MENUS')}</Text>
            </View>
          </View>
        );
    }else{
        return (<View><Text>{i18n.t('MENUS')}</Text></View>);
    }
}


export default Menus;