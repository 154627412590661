import React from 'react';
import { ActivityIndicator, Button, Image, ImageBackground, Pressable, SafeAreaView, Text, TextInput, View} from 'react-native';
import Constants from 'expo-constants';

import Logger from '../components/logger';
import { AuthContext} from '../components/authcontext';
import { i18n, setLanguage } from '../components/languages';
import { dpi, styles, theme } from '../components/styles'; 

import AppConfigScreen from './appconfigscreen';


const langs = {
    "en": {
        "F001001": "Login"
    },
    "th": {
        "F001001": "เข้าระบบ"
    }
}

function SignInScreen({ navigation }){
    // const[getter, setter] = default value state
    const[username, setUsername] = React.useState('');
    const[password, setPassword] = React.useState('');
    const[disabledSignIn, setDisabledSignIn] = React.useState(false);

    const { signIn, getAppConfig } = React.useContext(AuthContext);
    var lang = "th";
    //getAppConfig();
    /*
    Logger.set();
    const appConfig = async () => await getAppConfig();
    var value = appConfig();
    Logger.debug("signin.screen.useEffect", value);
    */
    //React.useEffect(async() => {
        
    //},[navigation]);
    //  onLongPress={() => { navigation.navigate('AppConfigScreen')}}
    return (
        <SafeAreaView style={[dpi > 1.5 ? styles.container : styles.containerCenter]}>
            <Pressable>
            {dpi > 1.5 ?? <View style={styles.row}><Text style={styles.logo}></Text></View>}
            <View style={styles.rowCenter}><Image style={styles.logo} source={require('../assets/propos-icon.png')} /></View>
            </Pressable>
            <View style={styles.rowCenter}><Text>{Constants.manifest.name}</Text></View>
            <View style={styles.rowCenter}><Text style={styles.textSubtitle}>{i18n.t('version')} {Constants.manifest.version}</Text></View>
            <View style={styles.rowCenter}>
                <View style={styles.flexButton}><Button title="TH" style={styles.button} onPress={() => setLanguage("th")} /></View>
                <View style={styles.flexButton}><Button title="EN" style={styles.button} onPress={() => setLanguage("en")} /></View>
            </View>

            <View style={{flex:1, justifyContent: 'center'}}>
                
                <TextInput style={styles.textInput} placeholder={i18n.t('username')} value={username} onChangeText={setUsername} autoCorrect={false}
                            autoFocus={true} onSubmitEditing={() => {setDisabledSignIn(true); signIn({username, password, navigation}); setTimeout(function () { setDisabledSignIn(false); setPassword(''); },3000); }} />
                <TextInput style={styles.textInput} placeholder={i18n.t('password')} value={password} onChangeText={setPassword} autoCorrect={false}
                            secureTextEntry onSubmitEditing={() => {setDisabledSignIn(true); signIn({username, password, navigation}); setTimeout(function () { setDisabledSignIn(false); setPassword(''); },3000); }} />

                <View style={styles.marginVertical}>
                    <Button title={i18n.t('F001001')} onPress={() => {setDisabledSignIn(true); signIn({username, password, navigation}); setTimeout(function () { setDisabledSignIn(false); setPassword(''); },3000); }} accessibilityLabel="sign in" disabled={disabledSignIn} />
                </View>
                {/*
                <View style={styles.marginVertical}>
                    <Button style={styles.button} title={i18n.t('SIGN_UP')} onPress={() => navigation.navigate('SignUp')} accessibilityLabel="sign up"  color="#888888" />
                </View>
                */}
            </View>
         </SafeAreaView>
    );

};


function SignUpScreen({navigation}){
    // const[getter, setter] = default value state
    const[username, setUsername] = React.useState('');
    const[password, setPassword] = React.useState('');
    const[confirm, setConfirm] = React.useState('');

    const { signUp } = React.useContext(AuthContext);

    return (
        <SafeAreaView style={styles.container}>

            <View style={styles.row}><Image style={styles.logo} source={require('../assets/propos-icon.png')} /></View>
            
            <View style={styles.row}><Text>{Constants.manifest.name}</Text></View>
            <View style={styles.row}><Text style={styles.textSubtitle}>{i18n.t('version')} {Constants.manifest.version}</Text></View>
            
            <View style={styles.row}>
            <View style={styles.flexButton}><Button title="TH" style={styles.button} onPress={() => setLanguage("th")} /></View>
                <View style={styles.flexButton}><Button title="EN" style={styles.button} onPress={() => setLanguage("en")} /></View>
            </View>


            <TextInput style={styles.textInput} placeholder={i18n.t('username')} value={username} onChangeText={setUsername} autoCorrect={false}/>
            <TextInput style={styles.textInput} placeholder={i18n.t('password')} value={password} onChangeText={setPassword} autoCorrect={false} secureTextEntry />
            <TextInput style={styles.textInput} placeholder={i18n.t('confirmPassword')} value={confirm} onChangeText={setConfirm}  autoCorrect={false}  secureTextEntry />
            <View style={styles.marginVertical}>
                <Button style={styles.button} title={i18n.t('register')} onPress={() => signUp({username, password, confirm})} />
            </View>
            <View style={styles.marginVertical}>
                <Button style={styles.button} title={i18n.t('back')} onPress={() => navigation.popToTop()}  color="#888888" />
            </View>
         </SafeAreaView>
    );

};


function SignOutScreen(){
    const { signOut } = React.useContext(AuthContext);

    return (
        <Button title="Sign out" onPress={signOut} />
    )
}


function SplashScreen(){
    return (
        <SafeAreaView>
            <ImageBackground source={require('../assets/splash.png')} resizeMode="cover" style={styles.ImageSplashScreen}>
            <ActivityIndicator size="large" color={theme.colors.secondary} />
            
            <View style={styles.rowCenter}><Text style={styles.textCenter}>{i18n.t('loading')}</Text></View>
            <View style={styles.rowCenter}><Text>{i18n.t('version')} {Constants.manifest.version}</Text></View>
            </ImageBackground>
            
        </SafeAreaView>
    ) 
};




/// https://stackoverflow.com/questions/38340500/export-multiple-classes-in-es6-modules
export { SplashScreen, SignInScreen, SignUpScreen };