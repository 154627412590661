import React from 'react';
import { Text, View } from 'react-native';
import { i18n } from '../components/languages';




function SwitchOutlet(){



    return ( <View>
        <Text>{i18n.t('F401035')}</Text>    
    </View>);
}


export default SwitchOutlet;