class Logger{
    static showLog = true;

    constructor(showLog){
        this.showLog = showLog === undefined ? true: showLog;;
    }

    // set show log
    static set(value){
        //console.log("set value", value);
        this.showLog = value === undefined ? false: value;;
    }

    static show(value){
        this.set(value === undefined ? true: showlue);
    }

    static log(text, obj){
        this.showLog ? obj === undefined ? console.log(text) : console.log(text, obj) : "";
    }

    static debug(text, obj){
        //console.log("Logger.debug", this.showLog);
        this.showLog ? obj === undefined ? console.debug(text) : console.debug(text, obj) : "";
    }

}


export default Logger;