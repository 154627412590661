import React from 'react';
import { ActivityIndicator, Text, View, SafeAreaView, FlatList, RefreshControl, ScrollView, TouchableOpacity } from 'react-native';
//import { TouchableOpacity } from 'react-native-gesture-handler';
import Constants from 'expo-constants';
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import { AuthContext} from '../components/authcontext';
import { i18n } from '../components/languages';
import { styles, theme } from '../components/styles';
import { GetRequest } from '../components/dataservice';
import Logger from '../components/logger';


Logger.set();

/// https://reactnative.dev/docs/network
function MenuGroupings({navigation, route}){
    //const [selectedId, setSelectedId] = React.useState(null);
    const { id, table } = route.params;
    const [state, setState] = React.useState({selectedId: null, data: null, refreshing: true});
    const { signOut } = React.useContext(AuthContext);
	Logger.set();
    Logger.log("menuGroupings");

    const bootstrapAsync = async() => {
        //console.log(Constants.manifest.extra.getMenuGroupings);
        //console.log(data);
        var response = await GetRequest(Constants.manifest.extra.getMenuGroupings).then((res) => {return res});
        //Logger.debug(response);
        if(response.status && response.status > 400){
            signOut();
        }else{
            setState({...state, data:response.data, isFetching: false});
        }
        Logger.log("menuGroupings bootstrapAsync end");
    }

    
    React.useEffect(async () =>{
        if(state.data === null){
            await bootstrapAsync();
        }
        Logger.log("menu.grouppings.useEffect");
    });


    const onRefresh = async () => {
        setState({...state, isFetching: true});
        bootstrapAsync();
    };
    
    

    /// https://stackoverflow.com/questions/53548361/react-native-touchableopacity-onpress-not-working-on-android
    const Item = ({ id, title, onPress, backgroundColor, textColor }) => (
        <TouchableOpacity key={id.toString()} onPress={onPress} style={[styles.item, backgroundColor]}>
        <View style={[styles.row]}>
            <Feather name="disc" size={32} color="black" />
            <Text style={[styles.title, textColor]}>&nbsp;{title}</Text> 
        </View>
        </TouchableOpacity>
    );



    const renderItem = ({ item }) => {
        //console.log(item.id);
        const backgroundColor = item.id === state.selectedId ? "#999999" : "#EEEEEE";
        const color = item.id === state.selectedId ? 'white' : 'black';
        
        return (<Item id={item.id} title={item.desc}
                    onPress={() => clickMenu(item.id, item.desc)} 
                    backgroundColor={{ backgroundColor }} textColor={{ color }}/>); 
    };

  
    //Logger.log("menuGroupings",table);
    /// https://stackoverflow.com/questions/43869197/check-if-an-array-is-empty-in-react-native
    return (state.data === null ? 
        <SafeAreaView style={styles.containerCenter}>
            <View style={styles.column}>
                <ActivityIndicator size="large" color={theme.colors.secondary} />
                <Text style={styles.textCenter}>Loading</Text>
            </View>
        </SafeAreaView> : 
        <SafeAreaView style={styles.container}>
            <FlatList style={{flex: 1}} data={state.data} renderItem={renderItem} keyExtractor={item => item.id}
                    onRefresh={onRefresh} refreshing={state.isFetching} />
        </SafeAreaView>
    );



    function clickMenu(id, desc){
        // console.log(id);
        //navigation.setParams({id:id});
        //navigation.setOptions({title:"title"});
        navigation.navigate('MenuDetails', { id: id, title: desc, table: table });

        setState({...state, selectedId: id});
    }

}


export default MenuGroupings;