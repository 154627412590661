import React from 'react';
import { ActivityIndicator, Alert, Button, FlatList, SafeAreaView, Text, View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { i18n } from '../components/languages';
import { styles, theme } from '../components/styles';
import { OrderContext } from '../components/ordercontext';
import Logger from '../components/logger';




function OrdersScreen({ navigation, route }){
  Logger.set();
  Logger.log("OrdersScreen() called");

  const { id } = route.params;
  const { getTable, removeOrderItem, orderTable, billTable } = React.useContext(OrderContext);
  const [state, setState] = React.useState({table: null, total: 0, selected: null, isFetching: true,
                           		showSendOrder: true, showSendBill: true, newOrder:-1});
  const isFocused = useIsFocused();
  var noIndex = 0;
  Logger.debug("order.screen.start");


  
  React.useEffect(async () => {

    if(isFocused || state.table === null){
      getTableUpdate();
      Logger.log("order.useEffect.isFocused");

      navigation.setOptions({
        title: state.newOrder > 0 ? i18n.t('ORDERS')+" (" + state.newOrder + ")": i18n.t('ORDERS')
      });
    }

  },[isFocused, state]);


  const onRefresh = async () => {
    setState({...state, isFetching: true});
    getTableUpdate();
  };


  const getTableUpdate = () => {
    let table = getTable();
    Logger.log("getTableUpdate", table);
    let newOrder = table.items.filter(({orderTime, isSetChild}) => orderTime === undefined && isSetChild === false);
    Logger.log("getTableUpdate newOrder", newOrder.length);
    if(state.newOrder !== newOrder.length)
      setState({...state, table: table, newOrder: newOrder.length, isFetching: false});
    if(state.isFetching)
      setState({...state, isFetching: false});
  }


  const onPressOrder = (item) => {
    Logger.log(item);
    setState({...state, selected: item.indexNo});
  }


  const onPressVoid = (item) => {
    alert("onPressVoid");
  }


  const onPressEdit = (item) => {
    alert("onPressEdit");
  }

  const onPressRemove = (item) => {
    Logger.log("onPressRemove");
    removeOrderItem(item).then(() => getTableUpdate());
  }


  const sendOrder = () =>{
    console.log("sendOrder start table", state.table);
    orderTable(id, state.table).then((response) => {
      if(typeof response !== 'string'){
        setState({...state, table: response, newOrder:0});
      }
    });
    console.log("sendOrder response");
  }


  const sendBill = () => {
    Logger.log("sendBill table" ,state.table);
    billTable(id, state.table);
    Logger.log("sendBill response",);
  }

  
  const Item = ({ item, no, onPress, onPressEdit, onPressRemove, onPressVoid, }) => (
  <TouchableOpacity onPress={onPress} style={[styles.item]} > 
    <View style={[styles.row]}>
      <Text style={[styles.text, styles.textRight, styles.width10pc ]}>
        {(no !== undefined && no < 0 && (no = 1, noIndex = 0, " "))}
        {no === undefined || no < 1 ? " - ": no + "."}
        </Text>
      <Text style={[styles.text, styles.width70pc ]}>{item.desc} {item.qty > 0 && "x "+ item.qty}</Text>
      <Text style={[styles.text, styles.textRight, styles.widthAuto]}>
         {item.price > 0 && (item.qty*item.price).toFixed(2).toLocaleString()}
         {item.netSale > 0 && (item.netSale).toFixed(2).toLocaleString()}
      </Text>
    </View>


    <View style={[styles.row]} >
      <View style={[styles.width70pc, styles.itemCenter, styles.itemContent]}>
        {item.modifiers && item.modifiers.map((modifier, index) => 
          <Text key={index +""+ item.menuCode +""+ modifier.indexNo + modifier.modId}> {modifier.desc}</Text>)}
      </View>
      <View style={[styles.width30pc, {alignContent: 'flex-end'}]}>
        {item.orderTime === undefined && no !== undefined && 
          <Button style={[styles.buttonWarning]} title="Remove" onPress={onPressRemove} />}
      </View>
    </View>
  </TouchableOpacity>);


  const renderItem = ({ item, index }) => {
    Logger.debug("renderItem", noIndex);
    //const backgroundColor = item.menuCode === state.selectedId ? "#999999" : "#EEEEEE";
    //const color = item.menuCode === state.selectedId ? 'white' : 'black';
    
    if(item.isSetChild){
      noIndex++;
      return (<Item item={item} />);
    }else{
      return (<Item item={item} no={1+index-noIndex} 
                  onPress={() => onPressOrder(item)} 
                  onPressEdit={() => onPressEdit(item)}
                  onPressRemove={() => onPressRemove(item)}
                  onPressVoid={() => onPressVoid(item)} />);
    }
  };


  //Logger.debug("order.screen.end");
  Logger.log("OrdersScreen() end", state.table);
  return (<SafeAreaView style={styles.container}>
    {state.table === null ? <View style={[styles.containerCenter]}>
        <ActivityIndicator size="large" color={theme.colors.secondary} />
        <View style={styles.rowCenter}><Text style={styles.text}>Loading</Text></View>
      </View>:
      state.table.items && <View style={styles.container}>
        <FlatList data={state.table.items} renderItem={renderItem} 
          keyExtractor={item => item.indexNo +""+ item.menuCode} 
          onRefresh={onRefresh} refreshing={state.isFetching}
          //initialNumToRender={state.table.items.length > 10 ? state.table.items.length-11: 0}
          initialScrollIndex={state.table.items.length > 10 ? state.table.items.length-1: 0}
          ListFooterComponent={()=> state.table.items && <View style={[styles.container]}>
            
          <View style={styles.row}>
            <Text style={[styles.text, styles.textRight, styles.width10pc ]}> </Text>
            <Text style={[styles.text, styles.text, styles.width70pc ]}>{state.table.billTotal === undefined || state.table.billTotal <= 0 ?  " ": " BILL TOTAL "}</Text>
            <Text style={[styles.text, styles.textRight, styles.widthAuto]}>{state.table.billTotal === undefined || state.table.billTotal <= 0 ?  " ": state.table.billTotal.toFixed(2).toLocaleString()} </Text>
          </View>
        </View>}
      />

      <View style={[styles.column, {alignContent:'flex-end'}]}>
        {state.newOrder > 0 && state.showSendOrder &&
        <TouchableOpacity style={[styles.button, styles.buttonPrimary ]} onPress={sendOrder} >
          <Text style={[styles.textButton]} id="send_order">{i18n.t('F401025')}</Text>
        </TouchableOpacity>}

        {state.table && state.table.billTotal > 0 && state.showSendBill > 0 &&
        <TouchableOpacity style={[styles.button, {backgroundColor: theme.colors.brightcyan}]} onPress={sendBill} >
          <Text style={[styles.textButton]} id="reprint_order">{i18n.t('F401018')}</Text>
        </TouchableOpacity>}
      </View>
      
     </View>
    }
  </SafeAreaView>);

}


export default OrdersScreen;