import React, { useState, useRef } from 'react';
import { NavigationContainer, useTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Constants from 'expo-constants';

import ModalDialog from './screens/modalDialog';
import ModalWait from './screens/modalWait';

import 'react-native-gesture-handler';
import { initLanguage, i18n } from './components/languages';
import { AuthContext} from './components/authcontext';
import { getConfig, setConfig, getSecureStore, setSecureStore } from './components/appconfig';

import IndexScreen from './screens/indexscreen';
import AppConfigScreen from './screens/appconfigscreen';
import { SplashScreen, SignInScreen, SignUpScreen } from './screens/signscreen';

import jwt_decode from 'jwt-decode';


// Ignore log notification by message:
//LogBox.ignoreLogs(['Warning: ...']);

// Ignore all log notifications:
//LogBox.ignoreAllLogs();




function App({ navigation }) {
  //const apiEndpoint = 'https://api.travelpro.in.th';
  const Stack  = createStackNavigator();  
  const modelWaitRef = useRef();
  const modalDialogRef = useRef();

  /// https://docs.expo.dev/workflow/configuration/
  //console.log(Constants.manifest.name);
  //console.log(Constants.manifest.extra.endpoint);
  

  /// https://reactjs.org/docs/hooks-reference.html#usereducer
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      //console.log(action);
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
          };
        case 'SIGN_UP':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
          };
        case 'APP_CONFIG':
          return {
            ...prevState,
            endpoint: action.endpoint,
            stationId: action.stationId,
            message: action.message,
            lang: action.lang,
            busDate: action.busDate,
            branchId: action.branchId,
            outletId: action.outletId,
            visible: action.visible === undefined ? false : action.visible,
            icon: action.icon === undefined ? prevState.icon : action.icon,
          };
        case 'SET_MODAL':
          return {
            ...prevState,
            visible: true,
            message: action.message.length === 0 ? "Something faulty": action.message,
            icon: action.icon === undefined ? prevState.icon : action.icon,
            button: action.buttonMessage === undefined ? "OK" : action.button
          };
        case 'RESET_MODAL': 
          return {
            ...prevState,
            visible: false,
            message: '',
            icon: null,
            button: ' OK '
          };
        default:
          return prevState;
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
      endpoint: null,
      stationId: null,
      lang: null,
      busDate: null,
      branchId: null,
      outletId: null,

      visible: false,
      message: '',
      icon: null,
      button: ' OK '
    }
  );




  const httpUtis = {
    postJson: async (url = '', data = {}) => {
      //console.log("postJson", url);
      const lang = await getConfig("locale");
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': lang
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      }).then(response => response)
      .catch(e => console.log("error",e));
      return response.json(); // parses JSON response into native JavaScript objects
    },
    getJson: async (url) => {
      //console.log("getJson", url);
      let uri = url;
      let result = null;
      //console.log("uri=" + uri);
      //console.log('!uri.startsWith("http") = ' + (!uri.startsWith("http")));

      if(!uri.startsWith("http")) {
        const endpoint = await getConfig("endpoint");
        if (endpoint != null 
            && endpoint != undefined) {
          uri = endpoint + url;
        }
      }

      //console.log("uri='" + uri + "'");
      const lang = await getConfig("locale");

      const response = await fetch(uri, {
          method: 'GET',
          headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          'Accept-Language': lang
        }
      }).then(response => response)
      .catch(e => console.log("error", e));;
      
      return response.json();

      /////////////////////
      // use in the future
      // var contenttype = response.headers.get("Content-Type");
      //
      // try 
      // {
      //   const json = contenttype.indexOf("json") >= 0 
      //     ? response.json()
      //     : JSON.parse(response.text());
      //
      //   console.log("json=");
      //   console.log(json);
      //
      //   if (typeof json === "object") {
      //     if (json.status >= 400) {
      //       throw json.errors;
      //     } else {
      //       if (json.data != null) {
      //         result = json.data;
      //       }
      //       if (json.error != null) {
      //         throw json.error;
      //       }
      //     }
      //   } else {
      //     throw json;
      //   }
      // }
      // catch (error)
      // {
      //   if (typeof error === "object") {
      //     var msg = "";
      //     Object.keys(error).map(function(key) {
      //       //console.log(error[key]);
      //       if(key === "messages"){
      //         error[key].map((value) =>{ msg += value + "\n"} );
      //       }
      //     });
      //
      //     dispatch({
      //       type:"SET_MODAL", 
      //       message: msg
      //     });
      //   } else {
      //     dispatch({
      //       type:"SET_MODAL", 
      //       message: error
      //     });
      //   }
      // }
      //
      // console.log("result=");
      // console.log(result);
      //
      // return result;
      // end use in the future
      ///////////////////
    },
    showError: async (error) => {      
      console.log("(error != null && error != undefined) => " + (error != null && error != undefined));
      modalDialogRef.current.show(error);
    }
  };


  /// https://reactjs.org/docs/hooks-reference.html#usememo
  const authContext = React.useMemo(() => ({
   
    signIn: async (data) => {
      //console.log("signIn", data);
      // In a production app, we need to send some data (usually username, password) to server and get a token
      // We will also need to handle errors if sign in failed
      // After getting token, we need to persist the token using `SecureStore` or any other encrypted storage
      // In the example, we'll use a dummy token
      // console.log("signIn");
      //console.log(data);
      // console.log(state);

      
      if(data.username === "admin" && data.password === "Admin@007"){
        data.navigation.navigate('AppConfigScreen');
      }else if(state.endpoint === undefined || state.endpoint === null){
        //dispatch({type:"SET_MODAL", message: "App does not have config data"});
        modalDialogRef.current.showDetail({type:"SET_MODAL", icon: "error", message: "App does not have config data"});
      }else{
        //console.log(state.endpoint + Constants.manifest.extra.login+":"+ state.stationId);
        var uri = state.endpoint + Constants.manifest.extra.login;
        const lang = await getConfig("locale");
        //console.log(uri);
        fetch(uri, {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            'Accept-Language': lang
          },
          body: JSON.stringify({
            username: data.username,
            password: data.password,
            stationid: state.stationId
          })
        }).then((response) => {
          //console.log(response);
          //const isJson = response.headers.get('content-type')?.includes('application/json');
          var contenttype = response.headers.get("Content-Type")

          return contenttype.indexOf("json") >= 0 ? response.json(): response.text();
        })
        .then((json) => {
          
          if(typeof json === "object"){
            if(json.status >= 400){
              
              throw json.errors;
            }else{
              // login pass
              // console.log(json);
              if(json.token != null){

                setSecureStore("userToken", json.token);

                dispatch({ type: 'SIGN_IN', token: json.token });
              }
              if(json.error != null){
                throw json.error;
              }
            }
          }else{
            throw json;
          }
        })
        .catch((error) => {
          //console.log("error", error);
          if(typeof error === "object"){
            var msg = "";
            Object.keys(error).map(function(key){
              //console.log(error[key]);
              
              if(key === "messages"){
                error[key].map((value) =>{ msg += value + "\n"} );
              }
            });
    
            //dispatch({type:"SET_MODAL", icon: error.icon, message: msg});
            modalDialogRef.current.showDetail({type:"SET_MODAL", icon: error.icon, message: msg});
          }else{

            //dispatch({type:"SET_MODAL", icon: error.icon, message: error});
            modalDialogRef.current.showDetail({type:"SET_MODAL", icon: error.icon, message: error});
          }
          
        })
        .finally(() => {
         return true;
        });
      }

    },
    signOut: async () => {
      setConfig("translations", null);
      await setSecureStore("userToken", null);
      dispatch({ type: 'SIGN_OUT' });
    },
    signUp: async (data) => {
      // In a production app, we need to send user data to server and get a token
      // We will also need to handle errors if sign up failed
      // After getting token, we need to persist the token using `SecureStore` or any other encrypted storage
      // In the example, we'll use a dummy token
      //console.log(state);

      //dispatch({type: "SET_MODAL", message: "Sory, NO function!"});
      modalDialogRef.current.showDetail({type: "SET_MODAL", message: "Sory, NO function!"});
      // bypass login
      //const userToken = 'dummy-auth-token';
      //setSecureStore("userToken", userToken);
      //dispatch({ type: 'SIGN_IN', token: userToken });
      //dispatch({ type: 'SIGN_UP', token: userToken });
    },
    getAppConfig: async () => { 
      //console.log("getAppConfig() called");
      //dispatch({ type: 'WAIT_A_MINUTE' });
      modelWaitRef.current.show();
      // get endpoint form asyncstorage
      return await getConfig("endpoint").then((endpoint) =>  endpoint)
      .then((endpoint) => {
        //console.log("getConfig(\"endpoint\")=", endpoint);
        getConfig("stationId").then((stationId) => {          
          //console.log("getConfig(\"stationId\")=", stationId);
          //console.log("endpoint", endpoint);
          
          if (endpoint === null 
            || stationId === null)
          {
            modelWaitRef.current.done();
            //console.log("getAppConfig() end with null");
            return { endpoint, stationId };
          }


          if(endpoint === null){

          }else{
            modelWaitRef.current.show();
          //////////////
          // get config busdate and workstation from database
          //////////////
          let busDate = null;
          let workStation = null;

          httpUtis.getJson(endpoint + "/api/busdates")
          .then( (data) => { 
            //console.log("data=", data);
            //console.log(data);

            busDate = data.data;

            //dispatch({ type: 'DONE' });
            modelWaitRef.current.done();

            //console.log("data.error != null => " + (data.error != null));

            if (data.error !== null && data.error.code !== "BUSDATES.NOT-DAILY-CLOSED") {
              dispatch({ 
                type: 'APP_CONFIG',
                endpoint: endpoint,
                stationId: stationId,
                busDate: null,
                branchId: null,
                outletId: null,
                lang: 'en'
              });
            
              httpUtis.showError(data.error);
            } else {
              httpUtis.getJson(endpoint + "/api/workstations/getbyid/" + busDate.branchId + "/" + stationId)
              .then( wData => {

                modelWaitRef.current.done();
                
                workStation = wData.data;

                //console.log("workStation=");
                //console.log(workStation);
                
                if (workStation == null
                  || workStation == undefined) {
                  httpUtis.showError(wData.error);
                  // sign out
                  setConfig("translations", null);
                  setSecureStore("userToken", null);
                  dispatch({ type: 'SIGN_OUT' });
                }
                else {
                  dispatch({ 
                    type: 'APP_CONFIG',
                    endpoint: endpoint,
                    stationId: stationId,
                    busDate: busDate.busDate,
                    branchId: busDate.branchId,
                    outletId: busDate.outletId,
                    lang: workStation.lang
                  });
    
                  //console.log("state=");
                  //console.log(state);
                }

                //console.log("getAppConfig() end");
              }); 
            }
          })
          .catch( (error) => {
            console.error("error=");
            console.error(error);

            httpUtis.showError(error);
          });
          ///////////////

          //return endpoint;
          //console.log("getAppConfig APP_CONFIG " + endpoint + ":" + stationId);          
          return { endpoint, stationId };
        }

        })
      })
      .finally(() => {
        //dispatch({ type: 'DONE' });
        //modelWaitRef.current.done();
      });
    },
    setAppConfig: async (data) => {
      //console.log("setAppConfig",data);
      //console.log(data);
      data.endpoint = data.endpoint.length === 0 ? null: data.endpoint;
      data.stationId = data.stationId.length === 0 ? null : data.stationId;

      await setConfig("endpoint", data.endpoint);
      await setConfig("stationId", data.stationId);
      

      dispatch({ type: 'APP_CONFIG', endpoint: data.endpoint, stationId: data.stationId }); 
                //message: "Config updated", visible: true, icon:<Feather name="info" size={48} color='orange' /> });
      modalDialogRef.current.showDetail({type:"SET_MODAL", icon: "info", message: "Config updated"});


    }
  }),[state]);

  
  /// https://reactjs.org/docs/hooks-reference.html#useeffect
  /// https://www.w3schools.com/react/react_useeffect.asp
  React.useEffect(() =>{
    // Fetch the foken from storage then navigate to out apptoptiate place
    const bootstrapAsync = async() => {
      //console.log("bootstrapAsync() called");
      if(state.userToken === null){
        try{
          // restore token storage in `secureStore` or ..
          let userToken = await getSecureStore("userToken");
          //console.log("await userToken=", userToken);        
          //console.log("userToken " + userToken);
          userToken = typeof userToken === 'object' || userToken === undefined ? null: userToken;
          //console.log(userToken);
          //console.log("(userToken !== null)=", (userToken !== null));
          //if (userToken !== null)
          await authContext.getAppConfig();

          dispatch({type: 'RESTORE_TOKEN', token: userToken});
        }catch(e){
          // restoring token failed
          console.error(e);
          return false;
        }
      }

      //console.log("bootstrapAsync() end");
      // after restoring token, we may need to validate it in production apps
      // this will switch to the App screeen or Auth screen and this loading screen wii be unmounted and throw any.
    };
    //console.log("useEffect", state);
    bootstrapAsync();
  }, []);
  

  const checkTokenExpirationMiddleware = token => next => action => {    
    if (jwtDecode(token).exp < Date.now() / 1000) {
      next(action);
      localStorage.clear();
    }
    next(action);
  };

  // check user token expired?
  if (state.userToken != null 
    && state.userToken != undefined) {
      const jwt = jwt_decode(state.userToken);
      //console.log("jwt=", jwt);
      if (jwt.exp < Date.now() / 1000) {
        state.userToken = null;
        authContext.signOut();
      }
  }


  initLanguage();


  return(
    <AuthContext.Provider value={authContext}>
      <NavigationContainer  >
        <Stack.Navigator>

          {state.isLoading ? ( // we haven't finished checking for the token yet
              <Stack.Screen name="Splash" component={SplashScreen}  options={{headerShown: false}} />
          ): state.userToken === null ? ( // Auth screens // no token found, user isn't signed in
              <Stack.Screen name="SignIn" component={SignInScreen} 
                            options={{ headerShown: false, animationTypeForReplace: state.isSignout ? 'pop': 'push'}} />
              
          ):( // user is signed in
              <Stack.Screen name="IndexScreen" component={IndexScreen} options={{headerShown: false}} />
          )}

          <Stack.Screen name="SignUp" component={SignUpScreen} options={{title: i18n.t('SIGN_UP')}} />
          <Stack.Screen name="AppConfigScreen" component={AppConfigScreen} options={{title:"ProPOS - Setting"}} />
        </Stack.Navigator>
         
      </NavigationContainer >
      <ModalDialog ref={modalDialogRef} />                        
      {/* <Modal
        animationType="slide"
        transparent={true}
        visible={state.visible}
        onRequestClose={() => { Alert.alert('Modal has been closed.'); dispatch({type:"RESET_MODAL"}); }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            {state.icon === null 
              ? <Feather name="alert-circle" size={48} color="blue" />
              : state.icon === "warning"
                ? <Feather name="alert-triangle" size={48} color="orange" />
                : state.icon === "error"
                  ? <Feather name="alert-triangle" size={48} color="red" />
                  : <Feather name="alert-circle" size={48} color="blue" /> }
            <Text style={styles.modalText} >{state.message} </Text>
            <Pressable style={[styles.button, styles.buttonClose]} onPress={() => {dispatch({type:"RESET_MODAL"})}}>
              <Text style={styles.textStyle}> {state.button} </Text>
            </Pressable>
          </View>
        </View>
      </Modal> */}
      <ModalWait ref={modelWaitRef} />      
    </AuthContext.Provider>
  )
}









export default App