import React, { useRef } from 'react';
import { FlatList, Text, SafeAreaView, View, Dimensions } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import Logger from '../components/logger';
import { i18n,initLanguage } from '../components/languages';
import { dpi, styles, theme } from '../components/styles';
import { initRequestHeader } from '../components/dataservice';
import { GetRequest } from '../components/dataservice';
import ModalDialog from '../screens/modalDialog';

import { Feather, MaterialIcons, MaterialCommunityIcons, SimpleLineIcons} from '@expo/vector-icons'; 



function HomeMenu({ navigation }) {
    const modalRef = useRef();
    const [selectedId, setSelectedId] = React.useState(null);
    const DATA = [
      {
        id: 'F301013',
        icon: <MaterialIcons name="restaurant" size={40} color="black" />,
        image: '',
        title: i18n.t('F301013'),
      },
      {
        id: 'F301014',
        icon: <MaterialIcons name="fastfood" size={40} color="black" />,
        image: '',
        title: i18n.t('F301014'),
      },
      /*{
        id: 'F101012',
        icon: <Feather name="disc" size={40} color="black" />,
        image: '',
        title: i18n.t('F101012'),
      },
      
      {
        id: 'PRINTS',
        icon: '',
        image: '',
        title: i18n.t('PRINTS'),
      },
      {
        id: 'LIST_MENU',
        icon: '',
        image: '',
        title: i18n.t('LIST_MENU'),
      },

      {
        id: 'demo-screen',
        icon: <SimpleLineIcons name="screen-desktop" size={40} color="black" />,
        image: '',
        title: "Demo UI " +dpi+ " dpi! ("+ Dimensions.get('window').width.toFixed() + "x" + Dimensions.get('window').height.toFixed() + ")"
      },
      */
    ];

    Logger.set();

    //React.useEffect(() =>{
      initLanguage();
      initRequestHeader();
    //});


    async function checkAuthen(id) {
      Logger.log("checkAuthen(id) called");
      var result = false;
      //const { signOut } = React.useContext(AuthContext);

      var response = await GetRequest("/api/functionlists/checkallow/" + id);
      Logger.log("id=" + id + "response=");
      Logger.log(response);

      if (response.status && response.status > 400) {
        //signOut();        
        //return false;
      } 
      else {
        
        if (response.error != null 
          || response.error != undefined
          || response.data == false) {
          throw response.error;
        }

        result = response.data;
        //var sessions = state.sessions;  
        // response.data.map((item) => sessions[item.flowId] === undefined ? sessions[item.flowId] = 1: sessions[item.flowId]++);
        // //console.log(sessions);
        // var sessionId = sessions && Object.keys(sessions)[0];
        // //console.log(sessionId);
        // //console.log(Object.keys(sessions)[0]);
        // setState({...state, data:response.data, statusId: id, sessions: sessions, sessionId: sessionId});
      }


      //console.log("checkAuthen(id) end");
      return result;
    }

    function clickHomeMenu(id){
        setSelectedId(id);

      if(false)//(dpi < 2) 
      {

      }else{

        checkAuthen(id).then((allow) => {
          switch(id) {
            //case "home": navigation.navigate("HomeScreen"); break;
            case "tables": navigation.navigate("TablesScreen"); break;
            case "menus": navigation.navigate("MenusScreen"); break;
            case "orders": navigation.navigate("OrdersScreen"); break;
            case "tabs-bottom": navigation.navigate("TabsBottomScreen"); break;
            case 'material-bottom-tabs': navigation.navigate("MeterialBottomTabs"); break;
            case 'material-top-tabs': navigation.navigate("MeterialTopTabs"); break;
            case 'demo-screen': navigation.navigate("DemoScreen"); break;
            
            case 'F301013': navigation.navigate("Dine"); break;
            case 'F301014': navigation.navigate("FastFood"); break;
            case 'F401035': navigation.navigate("SwitchOutlet"); break;

            case 'SELLINGS':
            case 'F301001':
            case 'F301002':
            case 'F301003':
            case 'F301004': navigation.navigate("Sellings"); break;

            case 'REPORTS':
            case 'F501001': navigation.navigate("Reports"); break;

            case 'LIST_MENU':
            case 'F301011':
            case 'F301012': navigation.navigate('ListMenus'); break;

            case 'PRINTS': navigation.navigate("Prints"); break;

            case 'DATA_FILES':
            case 'F301005':
            case 'F301006': navigation.navigate("DataFiles"); break;

            case 'TAX_INVOICES':
            case 'F301007':
            case 'F301008':
            case 'F301009': navigation.navigate("TaxInvoices"); break;

            case 'F101012': navigation.navigate("MenuGroupings", {id: 0, table: {}}); break;

            default: alert("click id " + id); break;
          }
        })
        .catch((error) => {
          //console.error(error);
          modalRef.current.show(error);
        });
      }
    };


    const Item = ({ title, icon, image, onPress, backgroundColor, textColor }) => (
        <TouchableOpacity onPress={onPress} style={[styles.flex, styles.item, backgroundColor]}>
          <View styles={[styles.flex]}>
            <Text style={[styles.textCenter]}>{image.length > 0 ? image: typeof(icon) === "object" ? icon :''}</Text>
            <Text style={[styles.textCenter, styles.title, textColor]}> {title} </Text>
          </View>
        </TouchableOpacity>
    );



    const renderItem = ({ item }) => {
        const backgroundColor = item.id === selectedId ? theme.colors.gray: theme.colors.surface;
        const color = item.id === selectedId ? theme.colors.white: theme.colors.black;
        // console.log(item.image.length + "|" + typeof(item.icon));
        return (<Item title={item.title} icon={item.icon} image={item.image} 
                      onPress={() => clickHomeMenu(item.id)} 
                      backgroundColor={{ backgroundColor }} textColor={{ color }}/>); 
    };


    return (<SafeAreaView style={styles.container}>
        <FlatList data={DATA} renderItem={renderItem} keyExtractor={item => item.id} />
        <ModalDialog ref={modalRef} />
    </SafeAreaView>);
    
}   


export default HomeMenu;



