import React from 'react';
import { View, Button } from 'react-native';

import { styles } from '../components/styles';


function TableStatusMenu(id){
    //console.log("TableStatusMenu(id) called");
    
    //console.log("id=");
    //console.log(id);

    const DATA = [
        {value: 0, text: "zero"},
        {value: 1, text: "one"},
        {value: 2, text: "two"},
        {value: 3, text: "three"},
    ];

    //console.log("TableStatusMenu(id) end");
    return (<View style={styles.rowCenter}>
        {DATA.map((data) => (<Button key={"status-"+data.value} styles={styles.buttonSquare} value={data.value} title={data.text} />))}
    </View>);

}


export default TableStatusMenu;