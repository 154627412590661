import React from 'react';
import { Text, TextInput, View, SafeAreaView } from 'react-native';
import { i18n } from '../components/languages';
import { styles } from '../components/styles';


function SearchScreen({ navigation, route}){
    const { id } = route.params;

    return (<SafeAreaView style={[styles.container]}>
        <View>    
            <TextInput style={[styles.textInput]} placeholder={i18n.t('SEARCH')} ></TextInput>
        </View>
    </SafeAreaView>);
}


export default SearchScreen;