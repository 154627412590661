import React from 'react';
import { Text, View } from 'react-native';



function Prints(){



    return ( <View>
        <Text>Prints</Text>    
    </View>);
}


export default Prints;