import React from 'react';
import { ActivityIndicator, Button, FlatList, View, Text, TextInput, SafeAreaView, Alert, Platform, TouchableOpacity } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Ionicons } from '@expo/vector-icons'; 
import Constants from 'expo-constants';

import { AuthContext} from '../components/authcontext';
import { OrderContext } from '../components/ordercontext'
import { i18n } from '../components/languages';
import { dpi, styles, theme } from '../components/styles';
import Logger from '../components/logger';

import MenuGroupings from './menuGroupings';
import OrdersScreen from './ordersscreen';
import SearchScreen from './searchscreen';



const Tab = createMaterialTopTabNavigator();


function TableDetailScreen({navigation, route}){
  const { id, outletId, table } = route.params;
  const [ state, setState] = React.useState({data: null, customers: 0, messages: null});
  // const { signOut } = React.useContext(AuthContext);
  // Logger.log("signOut",signOut);
  const { openTable, updateTable, closeTable } = React.useContext(OrderContext);



  // check table status open or not
  const backAction = () => {
        Alert.alert("Hold on!", "Are you sure you want to go back?", [
          {
            text: "Cancel",
            onPress: () => null,
            style: "cancel"
          },
          { text: "YES", onPress: () => closeTable(id)  } // BackHandler.exitApp()
        ]);
        return false;
  };

  async function bootstrapAsync(){
    //Logger.log("table.details.screen",state);
    /// https://reactnavigation.org/docs/header-buttons/#header-interaction-with-its-screen-component
    navigation.setOptions({ 
      title: i18n.t('TABLE') +" " + id + " (F"+outletId+")",
      gestureEnabled: true
    });


    if(state.data === null && state.messages === null)  //init
    {    
      //var response = await 
      openTable(id).then((response) => {
        //Logger.log(response)
        if(typeof response === 'string'){
          setState({...state, messages: response});
        }else{
          setState({...state, messages: null, data: response});
        }
        //Logger.log("opentable end");
      });
      
    } 


    /// https://reactnavigation.org/docs/custom-android-back-button-handling/
    navigation.addListener('beforeRemove', (e) => { 
        closeTable(id);
    });
       
    //Logger.log("table.detail.screen.end");
  }


  React.useEffect(() =>{
    bootstrapAsync();
    //Logger.log("table.detail.screen.useEffect");
  },[navigation, state]); // 


  const OpenTable = () => (<SafeAreaView style={[styles.container, { justifyContent: 'space-around'}]}>
    <View style={styles.rowCenter}>
      <Text style={[styles.text, styles.h4]}>{i18n.t("F401001")} {id}</Text>
    </View>
    <View style={styles.rowCenter}>
      <Text style={[styles.text, styles.textRight, styles.h6, {marginVertical: 5}]}>{i18n.t("F101008")}</Text>
      <TextInput defaultValue={state.data.numberOfCustomers === undefined || state.data.numberOfCustomers === 0 ? state.customers.toString(): state.data.numberOfCustomers.toString()}  
        style={[styles.inputNumber]} type="number" selectTextOnFocus={true} keyboardType="number-pad"
        onChangeText={(value) => setState({...state, customers:isNaN(parseInt(value)) ? 0: parseInt(value)})} autoCorrect={false}/>
    </View>

    <View style={[styles.column,{ alignContent:'stretch' }]}>
      <TouchableOpacity style={styles.buttonPrimary} onPress={setNumberOfCustomer}>
        <Text style={styles.textButton}>{i18n.t("F401001")}</Text>
      </TouchableOpacity>
    </View>
  </SafeAreaView>);


  //Logger.log("table.detail.screen.return");
  // menu (with search) / order (with search)
  //console.log("table.detail.screen.render ");
  return(state.data === null || state.data === undefined || state.messages !== null ? 
    <View style={[styles.containerCenter]}>
      <View style={styles.column}>
      {state.messages === null ? <ActivityIndicator size="large" color={theme.colors.secondary} />: 
      <Ionicons name="warning-outline" size={128} color="black" />}
      <View style={styles.rowCenter}>
        <Text style={styles.textSubtitle}>{state.messages === null ? "Loading": state.messages}</Text>
      </View>
      <View style={styles.rowCenter}>
        <Button title="Back" onPress={() => navigation.goBack()} />
      </View>
      </View>
    </View>: 
    state.data.numberOfCustomers === 0 ? 
    <OpenTable></OpenTable>:
    <Tab.Navigator>
        <Tab.Screen name="Menus"  options={{title:i18n.t('MENUS')}}  component={MenuGroupings} initialParams={{id : id, table: state.data}} />
        <Tab.Screen name="Orders" options={{title:i18n.t('ORDERS')}} component={OrdersScreen}  initialParams={{id : id, table: state.data}} />
        <Tab.Screen name="Search" options={{title:i18n.t('SEARCH')}} component={SearchScreen}  initialParams={{id : id, table: state.data}} />
    </Tab.Navigator>
  );


  function setNumberOfCustomer(){
    if(state.customers > 0){
      let data = state.data; 
      data.numberOfCustomers = state.customers; 

      updateTable(data);
      setState({...state, data:data});
    }else{
      if(Platform.OS === 'web'){
        alert(i18n.t("F101008") + " > 0");
      }else{
        Alert.alert(i18n.t("F401001"), i18n.t("F101008") + " > 0",[{text: "OK", onPress: () => {}}]);
      }
    }
  }
    
}



export default TableDetailScreen;