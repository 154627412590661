import React from 'react';
import { ActivityIndicator, AppState, Animated, SafeAreaView, FlatList, Text, View, TouchableOpacity } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { TabActions , useIsFocused, NavigationContainer  } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import Constants from 'expo-constants';

import { OrderContext } from '../components/ordercontext';
import { GetRequest } from '../components/dataservice';
import { styles, theme } from '../components/styles';
import { getConfig } from '../components/appconfig'

import TableControl from './tableControl';
import TableStatusMenu from './tableStatusMenu';
import TablesScreen from './tablesscreen';
import TableDetailScreen from './tabledetailscreen';
import Logger from '../components/logger';



const Tab = createMaterialTopTabNavigator();
const Stack  = createStackNavigator();  

function Dine({navigation, route}){
    Logger.set();
    const isFocused = useIsFocused();

    async function bootstrapAsync(){ }

    const [state, setState] = React.useState({
        data: null,
        nameBlank: "โต๊ะว่าง",
        nameBusy: "มีลูกค้า",
        nameBilling: "เช็คบิล",
        nameAll: "ทั้งหมด"
    });

    React.useEffect(() =>{
        if(isFocused) getSummary();
    },[navigation, isFocused]); // , 

    
    function getSummary() {
        // get status summary
        GetRequest("/api/Tables/StatusSummary").then( (response) => {
            Logger.log("response", response);
            
            if (response.status 
            && response.status > 400) {
                signOut();
            } else {            
                let nameBilling = "";
                let nameAll = "";
                let nameBlank = "";
                let nameBusy = "";

                getConfig("local").then((lang) => {
                    //Logger.log("lang", lang);
                    if (lang == "en") {
                        nameBilling = "billing (" + response.data.billing + ")";
                        nameAll = "all (" + response.data.all + ")";
                        nameBlank = "blank (" + response.data.blank + ")";
                        nameBusy = "busy (" + response.data.busy + ")";
                    } else {                    
                        nameBilling = "เช็คบิล (" + response.data.billing + ")";
                        nameAll = "ทั้งหมด (" + response.data.all + ")";
                        nameBlank = "โต๊ะว่าง (" + response.data.blank + ")";
                        nameBusy = "มีลูกค้า (" + response.data.busy + ")";
                    }    

                    Logger.log("nameBlank = ", nameBlank
                        + ", nameBusy = " + nameBusy
                        + ", nameBilling = " + nameBilling
                        + ", nameAll = " + nameAll);

                    setState({ ...state,
                        data: response.data,
                        nameAll: nameAll, 
                        nameBilling: nameBilling,
                        nameBusy: nameBusy, 
                        nameBlank: nameBlank 
                    });
                });
            }
        });
    }

    
    /// https://reactnavigation.org/docs/material-top-tab-navigator/
    //return (<OrderContext.Provider value={tableValue}></OrderContext.Provider>);
    return(state.data === null 
        ? <View style={[styles.containerCenter]}>
            <View style={styles.column}>
                <ActivityIndicator size="large" color={theme.colors.secondary} />
                <Text style={styles.textCenter}>Loading</Text>
            </View>
        </View>
        : <Tab.Navigator> 
            <Tab.Screen name={state.nameBlank}   component={TablesScreen} initialParams={{id : "blank"}} />
            <Tab.Screen name={state.nameBusy}    component={TablesScreen} initialParams={{id : "busy"}} />
            <Tab.Screen name={state.nameBilling} component={TablesScreen} initialParams={{id : "billing"}} />
            <Tab.Screen name={state.nameAll}     component={TablesScreen} initialParams={{id : "all"}} />
        </Tab.Navigator>);
}


export default Dine;